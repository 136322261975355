import { CountryGroup } from '@faire/web-api--source/indigofair/data/CountryGroup';
import { StateOrProvince } from '@faire/web-api--source/indigofair/data/StateOrProvince';
import { ISO3166Alpha3 } from '@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3';

export namespace IGeoConstraint {
  /**
   * Return an instance of IGeoConstraint with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IGeoConstraint>): IGeoConstraint =>
    ({
      ...(partial ?? {}),
    }) as IGeoConstraint;
}

export interface IGeoConstraint {
  country: keyof typeof ISO3166Alpha3 | undefined;
  country_group: keyof typeof CountryGroup | undefined;
  state_or_province: StateOrProvince | undefined;
}
