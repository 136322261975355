import { getSettingValueWithoutAssignment } from "@faire/retailer-visitor-shared/settings/__internal__/getSetting";
import { useAssignSetting } from "@faire/retailer-visitor-shared/settings/__internal__/useSetting";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { NEXTJS_RSC_LIBP_MIGRATION_241121 } from "@faire/web-api--source/indigofair/settings/NEXTJS_RSC_LIBP_MIGRATION_241121";

export const getSettingRscLibp = (rscParam?: string | undefined): boolean => {
  if (rscParam === "1") {
    return true;
  }
  if (rscParam === "0" || !getGlobalProperty("isNextJS", false)) {
    return false;
  }
  return getSettingValueWithoutAssignment(
    NEXTJS_RSC_LIBP_MIGRATION_241121,
    false
  );
};

export const useAssignSettingRscLibp = () =>
  useAssignSetting(NEXTJS_RSC_LIBP_MIGRATION_241121);
