import { isAppDownloadPath } from "@faire/retailer-visitor-shared/app/_lib/wrappers/appDownloadWrapperUtils";
import { MobileAppSnackbarWrapper } from "@faire/retailer-visitor-shared/components/App/MobileAppSnackbarWrapper";
import { AppDownloadNudgeWrapper } from "@faire/retailer-visitor-shared/components/AppDownloadNudge/AppDownloadNudgeWrapper";
import { useIsAppInstalled } from "@faire/retailer-visitor-shared/hooks/pageData/useIsAppInstalled";
import { useIsLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import {
  useAssignSettingNuxAppDownloadNudges,
  useSettingNuxAppDownloadNudges,
} from "@faire/retailer-visitor-shared/settings/useSettingNuxAppDownloadNudges";
import { useViewport } from "@faire/web--source/ui/hooks/useViewport";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const AppDownloadWrapper = () => {
  const { pathname } = useLocation();
  const { isMobile } = useViewport();
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const isNuxAppDownloadNudgesEnabled = useSettingNuxAppDownloadNudges();
  const assignSettingNuxAppDownloadNudges =
    useAssignSettingNuxAppDownloadNudges();
  const isAppInstalled = useIsAppInstalled();

  const shouldAssign =
    isMobile &&
    isLoggedInRetailer &&
    isAppInstalled === false &&
    isAppDownloadPath(pathname);

  useEffect(() => {
    if (shouldAssign) {
      assignSettingNuxAppDownloadNudges();
    }
  }, [assignSettingNuxAppDownloadNudges, shouldAssign]);

  return isNuxAppDownloadNudgesEnabled ? (
    <AppDownloadNudgeWrapper />
  ) : (
    <MobileAppSnackbarWrapper />
  );
};
