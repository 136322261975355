import { useIsLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { parseQueryParams } from "@faire/web--source/common/query-params/parseQueryParams";
import { RetailerRoute } from "@faire/web--source/common/routes/RetailerRouteMatcher";
import { RouteMatcher } from "@faire/web--source/common/routes/RouteMatcher";
import { useViewport } from "@faire/web--source/ui/hooks/useViewport";
import { useQuery as useShowNuxAppDownloadNudgeQuery } from "@faire/web-api--source/endpoints/www/api/retailer/show-nux-app-download-nudge/get-hooks";
import { useLocation } from "react-router-dom";

import { APP_DOWNLOAD_NUDGE_ALLOWED_PATHS } from "@faire/retailer-visitor-shared/app/_lib/wrappers/appDownloadWrapperUtils";

import { useGetMobileAppUrl } from "@faire/retailer-visitor-shared/components/AppDownloadNudge/useGetMobileAppUrl";
import { useGetNudgeDismissInfo } from "@faire/retailer-visitor-shared/components/AppDownloadNudge/useGetNudgeDismissInfo";

export const useCanShowAppDownloadNudge = () => {
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const { isMobile } = useViewport();
  const { pathname, search } = useLocation();
  const { isDismissOverLimit } = useGetNudgeDismissInfo();
  // endpoint checks app install status and enrolls user in Conductor campaign
  const { data, isLoading } = useShowNuxAppDownloadNudgeQuery();
  const queryParams = parseQueryParams(search);
  const ctaUrl = useGetMobileAppUrl(queryParams);

  const allowedRouteMatcher: RouteMatcher<RetailerRoute> = new RouteMatcher(
    APP_DOWNLOAD_NUDGE_ALLOWED_PATHS
  );
  const isLocationOnAllowedList = !!allowedRouteMatcher.match(pathname);

  return (
    isMobile &&
    isLoggedInRetailer &&
    isLocationOnAllowedList &&
    !isLoading &&
    data?.should_show_nudge &&
    !!ctaUrl &&
    !isDismissOverLimit
  );
};
