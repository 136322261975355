import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";

// Define UXCam interface for global window object
interface UXCamInterface {
  __t: any[];
  __ak: string;
  __o: Record<string, any>;
  event: (name: string, properties?: Record<string, any>) => void;
  setUserIdentity: (identity: string) => void;
  setUserProperty: (key: string, value: any) => void;
  setUserProperties: (properties: Record<string, any>) => void;
}

declare global {
  interface Window {
    uxc?: UXCamInterface;
  }
}

/**
 * Initializes UXCam session recording
 * @param appKey - The UXCam application key
 * @param opts - Optional configuration options for UXCam
 */
export default function startUXCam(
  appKey: string,
  opts: Record<string, any> = {}
): void {
  // Check if we're in a browser environment
  if (typeof getGlobalProperty<Window>("window") === "undefined") {
    return;
  }

  // Initialize UXCam global object with tracking methods
  const globalWindow = getGlobalProperty<Window>("window");
  if (!globalWindow) {
    return;
  }

  // Check if UXCam is already initialized
  if (globalWindow.uxc) {
    return;
  }

  globalWindow.uxc = {
    __t: [],
    __ak: appKey,
    __o: opts,
    event: function (name: string, properties?: Record<string, any>) {
      this.__t.push(["event", name, properties]);
    },
    setUserIdentity: function (identity: string) {
      this.__t.push(["setUserIdentity", identity]);
    },
    setUserProperty: function (key: string, value: any) {
      this.__t.push(["setUserProperty", key, value]);
    },
    setUserProperties: function (properties: Record<string, any>) {
      this.__t.push(["setUserProperties", properties]);
    },
  };

  // Load UXCam script
  const doc = globalWindow.document;
  const head = doc.getElementsByTagName("head")[0];
  if (!head) {
    return;
  }

  const script = doc.createElement("script");
  script.type = "text/javascript";
  script.src = "//websdk-recording.uxcam.com/index.js";
  script.async = true;
  script.defer = true;
  script.id = "uxcam-web-sdk";
  script.crossOrigin = "anonymous";
  head.appendChild(script);
}
