import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";

import startUXCam from "./startUXCam";

export class UXCam {
  /**
   * Initializes UXCam with the given application key and options
   * @param appKey - The UXCam application key
   * @param opts - Optional configuration options for UXCam
   */
  static initialize = (
    appKey: string,
    opts: Record<string, any> = {}
  ): void => {
    startUXCam(appKey, opts);
  };

  /**
   * Tracks a custom event with optional properties
   * @param eventName - The name of the event to track
   * @param properties - Optional properties associated with the event
   */
  static event = (
    eventName: string,
    properties?: Record<string, any>
  ): void => {
    const globalUXCam = getGlobalProperty<any>("uxc");
    if (!globalUXCam) {
      throw new Error("UXCam is not initialized");
    }
    globalUXCam.event(eventName, properties);
  };

  /**
   * Sets the user identity for the current session
   * @param identity - The user identity to set
   */
  static setUserIdentity = (identity: string): void => {
    const globalUXCam = getGlobalProperty<any>("uxc");
    if (!globalUXCam) {
      throw new Error("UXCam is not initialized");
    }
    globalUXCam.setUserIdentity(identity);
  };

  /**
   * Sets a single user property
   * @param key - The property key
   * @param value - The property value
   */
  static setUserProperty = (key: string, value: any): void => {
    const globalUXCam = getGlobalProperty<any>("uxc");
    if (!globalUXCam) {
      throw new Error("UXCam is not initialized");
    }
    globalUXCam.setUserProperty(key, value);
  };

  /**
   * Sets multiple user properties at once
   * @param properties - Object containing user properties
   */
  static setUserProperties = (properties: Record<string, any>): void => {
    const globalUXCam = getGlobalProperty<any>("uxc");
    if (!globalUXCam) {
      throw new Error("UXCam is not initialized");
    }
    globalUXCam.setUserProperties(properties);
  };
}
