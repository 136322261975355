import { logError } from "@faire/web--source/common/logging";
import getShouldDisplayPasswordModal from "@faire/web-api--source/endpoints/www/api/v2/users/display-password-modal/get";
import { useQuery as useShouldResetPasswordQuery } from "@faire/web-api--source/endpoints/www/api/v2/users/display-password-modal/get-hooks";

export const useShouldDisplayResetPasswordAfterLogin = () => {
  const { data, isLoading } = useShouldResetPasswordQuery();
  const shouldShowResetPassword = data?.should_display_password_modal ?? false;
  return {
    shouldShowResetPassword,
    isLoading,
  };
};

export const fetchShouldDisplayResetPasswordAfterLogin = async () => {
  try {
    const response = await getShouldDisplayPasswordModal();
    return response.should_display_password_modal;
  } catch (error) {
    logError(error);
  }
  return false;
};
