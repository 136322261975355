import { removeLocaleFromUrl } from "@faire/web--source/ui/routing/util";

export function isPathnameEqual(a: string, b: string) {
  return processPathname(a) === processPathname(b);
}

function processPathname(rawPathname: string) {
  // Comparison agnostic of locale prefix
  const decoded = decodeURI(removeLocaleFromUrl(rawPathname) || "/");
  if (decoded.endsWith("/")) {
    // Comparison agnostic of trailing slash
    return decoded.slice(0, -1);
  }
  return decoded;
}
