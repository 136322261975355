import {
  CLOVER,
  SHOPIFY,
  SQUARE,
} from "@faire/retailer-visitor-shared/features/PointOfSale/consts";
import { POSType } from "@faire/retailer-visitor-shared/features/PointOfSale/POSType";

export const getPOSName = (type: POSType | undefined): string => {
  let name;
  switch (type) {
    case SQUARE:
      name = "Square";
      break;
    case SHOPIFY:
      name = "Shopify";
      break;
    case CLOVER:
      name = "Clover";
      break;
    default:
      name = "POS or online store";
  }
  return name;
};
