import { SettingConst } from '@faire/web-api--source/types';

/**
 * The site key for an hCaptcha integration. This value is not secret.
 */
export const HCAPTCHA_SITE_KEY = 'HCAPTCHA_SITE_KEY' as SettingConst<
  'HCAPTCHA_SITE_KEY',
  string,
  'RETAILER' | 'VISITOR_WEB'
>;
