import { debounceIfPossible } from "@faire/web--source/common/globals/debounceIfPossible";
import { useIsSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import { getEstimatedViewportType } from "@faire/web--source/common/user-agent/getEstimatedViewportType";
import {
  getWindowHeight,
  getWindowWidth,
} from "@faire/web--source/ui/hooks/useObservableWindow";
import { useCallback, useMemo, useState } from "react";

import { useHandleResize } from "./useHandleResize";

const getWindowDimension = () => ({
  width: getWindowWidth(),
  height: getWindowHeight(),
});

export const useViewportDimension = <T extends number | boolean | string>(
  deriveFromDimension: (dimension: { width: number; height: number }) => T
) => {
  const isSSR = useIsSSR();

  const initialValue = useMemo(() => {
    if (isSSR) {
      const { width, height } = getEstimatedViewportType();
      return deriveFromDimension({ width, height });
    }

    return deriveFromDimension(getWindowDimension());
  }, [deriveFromDimension, isSSR]);

  const [value, setValue] = useState(initialValue);

  const handleResize = useCallback(() => {
    setValue(deriveFromDimension(getWindowDimension()));
  }, [deriveFromDimension]);

  const debouncedHandleResize = useMemo(
    () => debounceIfPossible(handleResize, 200),
    [handleResize]
  );

  useHandleResize(debouncedHandleResize, {
    fireImmediately: true,
  });

  return value;
};
