export namespace IUserEvent {
  /**
   * Return an instance of IUserEvent with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IUserEvent>): IUserEvent =>
    ({
      ...(partial ?? {}),
    }) as IUserEvent;
}

export interface IUserEvent {
  /**
   * Path of the event.
   */
  path: string | undefined;
  /**
   * Moment in time that the event fired on the client.
   */
  client_created_at: number | undefined;
  /**
   * Subdomain the user was on when the event fired. inalytics should be accessed at events.api.<root domain>.
   * This was added to capture the subdomain for faire direct links. (https://github.com/Faire/backend/pull/19010)
   * In Mar 2023, we migrated brands off of subdomains and instead use a subfolder or query params otherwise.
   *       brandAlias.faire.com -> faire.com/direct/brandAlias.
   *       brandAlias.faire.com/product/p_123 -> faire.com/product/p_123?fdb=brandAlias (FD-125013)
   * We'll keep this field present to for the time being for compatibility with our event pipeline.
   */
  subdomain: string | undefined;
}
