import { useSettingDecentralizePageData } from "@faire/retailer-visitor-shared/settings/useSettingDecentralizePageData";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { useQuery as useIsAppInstalledQuery } from "@faire/web-api--source/endpoints/www/api/page-data/is-app-installed/get-hooks";

export const useIsAppInstalled = (): boolean | undefined => {
  const shouldDecentralizePageData = useSettingDecentralizePageData();
  const { data } = useIsAppInstalledQuery({
    enabled: shouldDecentralizePageData,
    refetchOnMount: false,
    staleTime: Infinity,
  });
  return shouldDecentralizePageData
    ? data?.is_app_installed
    : getGlobalProperty("isAppInstalled");
};
