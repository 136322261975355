import { IPerfDarkReadsConfig } from '@faire/web-api--source/indigofair/settings/IPerfDarkReadsConfig';
import { SettingConst } from '@faire/web-api--source/types';

/**
 * Configuration for enabling global performance dark read test.
 * Endpoints annotated @DarkReads will replicate extra traffic based on the configuration, optionally
 * reduced to a subset using the `PerfDarkReadsConfig.endpoints` regexp allowlist.
 */
export const PERF_DARK_READ_TESTING_ENABLED =
  'PERF_DARK_READ_TESTING_ENABLED' as SettingConst<
    'PERF_DARK_READ_TESTING_ENABLED',
    IPerfDarkReadsConfig,
    'RETAILER'
  >;
