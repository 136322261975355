"use client";

import React from "react";

export const NextCacheTrackingContextProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  return (
    <NextCacheTrackingContext.Provider
      value={{
        possibleCachedHrefs: React.useRef<Record<string, number>>({}),
      }}
    >
      {props.children}
    </NextCacheTrackingContext.Provider>
  );
};

export interface INextCacheTrackingContext {
  possibleCachedHrefs?: React.RefObject<Record<string, number>>;
}

export const NextCacheTrackingContext: React.Context<INextCacheTrackingContext> =
  React.createContext({
    possibleCachedHrefs: undefined,
  } as INextCacheTrackingContext);

export const useNextCacheTrackingContext = () => {
  return React.useContext(NextCacheTrackingContext);
};
