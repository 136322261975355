import { useCallback } from "react";

import { useViewportDimension } from "./__internal__/useViewportDimension";

export const useViewportWidth = <T extends number | boolean | string>(
  deriveFromWidth: (width: number) => T
) => {
  const deriveFromDimension = useCallback(
    ({ width }: { width: number }) => {
      return deriveFromWidth(width);
    },
    [deriveFromWidth]
  );

  return useViewportDimension<T>(deriveFromDimension);
};
