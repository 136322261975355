import { request } from '@faire/web-api--source';
import { IUserEvent } from '@faire/web-api--source/indigofair/data/IUserEvent';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/event`;
};

export const build = (
  data: IUserEvent,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    ignoreDuringPrerender: true,
    ignoreForRobots: true,
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/event';

/**
 *   Endpoint for recording PETE (Platform for Explicitly Tracked Events) events.
 *   Don't call this endpoint directly - define your event at https://www.faire.com/pete and import the helper function
 *   from @faire/web-api--source using the code-snippet provided in the PETE UI.
 * @deprecated This endpoint is being deprecated because it does not return a proto
 * Product Area: CLIENT_EVENTS
 * Ignored (not called) during prerender sessions.
 * Ignored (not called) during bot sessions.
 */
function execute(data: IUserEvent): Promise<boolean>;
function execute(
  data: IUserEvent,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<boolean>>;
function execute(data: IUserEvent, options?: EndpointOptions): Promise<boolean>;
function execute(
  data: IUserEvent,
  options?: EndpointOptions
): Promise<boolean | SuccessResponse<boolean>> {
  const { route, ...requestOptions } = build(data, options);
  return request<boolean>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/event/post';
export default execute;
