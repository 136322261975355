export const B3_HEADER_SAMPLED = "x-b3-sampled";
export const B3_HEADER_SPAN = "x-b3-spanid";
export const B3_HEADER_TRACE = "x-b3-traceid";

export const DD_HEADER_SAMPLED = "x-datadog-sampling-priority";
export const DD_HEADER_SPAN = "x-datadog-parent-id";
export const DD_HEADER_TRACE = "x-datadog-trace-id";
export const DD_HEADER_ORIGIN = "x-datadog-origin";

export const FAIRE_SPAN_ID_TRACKING = "x-faire-span-id-tracking";
