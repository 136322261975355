import { useSetting } from "@faire/retailer-visitor-shared/settings/__internal__/useSetting";
import { IAppPromotionBannerLinks } from "@faire/web-api--source/indigofair/data/IAppPromotionBannerLinks";
import { APP_PROMOTION_BANNER_LINKS } from "@faire/web-api--source/indigofair/settings/APP_PROMOTION_BANNER_LINKS";

export const useSettingAppPromotionBannerLinks =
  (): IAppPromotionBannerLinks => {
    return useSetting(
      APP_PROMOTION_BANNER_LINKS,
      IAppPromotionBannerLinks.build()
    );
  };
