import { Language } from '@faire/web-api--source/indigofair/languages/Language';
import { IPosLocation } from '@faire/web-api--source/indigofair/retailers/pos/IPosLocation';
import { IRetailerPosCategory } from '@faire/web-api--source/indigofair/retailers/pos/IRetailerPosCategory';

export namespace IRetailerPosLinkSettings {
  export namespace INewItemSettings {
    export enum ProductState {
      PRODUCT_STATE_UNKNOWN = 'PRODUCT_STATE_UNKNOWN',
      DRAFT = 'DRAFT',
      ACTIVE = 'ACTIVE',
    }

    export enum DefaultVariantCreationMethod {
      DEFAULT_VARIANT_CREATION_METHOD_UNKNOWN = 'DEFAULT_VARIANT_CREATION_METHOD_UNKNOWN',
      /**
       * When variants have the same product, create them as children of the same POS Product
       */
      GROUP = 'GROUP',
      /**
       * Create a new POS Product->Variant for every variant
       */
      SPLIT = 'SPLIT',
    }

    export enum NameFormat {
      /**
       * Use just the item name i.e.: "ITEM_NAME".
       */
      ITEM_NAME = 'ITEM_NAME',
      /**
       * Prefix the item name with the brand's name i.e. "BRAND_NAME – ITEM_NAME".
       */
      BRAND_PREFIX = 'BRAND_PREFIX',
      /**
       * Append the product option name i.e. "ITEM_NAME - OPTION_NAME".
       */
      OPTION_SUFFIX = 'OPTION_SUFFIX',
      /**
       * Prefix with the brand's name and append option name i.e. "BRAND_NAME – ITEM_NAME - OPTION_NAME".
       */
      BRAND_AND_OPTION = 'BRAND_AND_OPTION',
    }

    /**
     * Return an instance of INewItemSettings with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<INewItemSettings>
    ): INewItemSettings =>
      ({
        ...(partial ?? {}),
      }) as INewItemSettings;
  }

  export interface INewItemSettings {
    name_format:
      | keyof typeof IRetailerPosLinkSettings.INewItemSettings.NameFormat
      | undefined;
    /**
     * A Pos CATEGORY previously supplied by the server.
     */
    category: IRetailerPosCategory | undefined;
    default_variant_creation_method:
      | keyof typeof IRetailerPosLinkSettings.INewItemSettings.DefaultVariantCreationMethod
      | undefined;
    append_faire_tag_to_product_listings: boolean | undefined;
    /**
     * ** Currently only used for Shopify *&#8205;/
     */
    default_product_state:
      | keyof typeof IRetailerPosLinkSettings.INewItemSettings.ProductState
      | undefined;
  }

  export enum InventoryUpdate {
    /**
     * Don't adjust inventory counts.
     */
    DISABLED = 'DISABLED',
    /**
     * Update inventory automatically using heuristics e.g. when the order is shipped.
     */
    AUTOMATIC = 'AUTOMATIC',
  }

  export enum DefaultCreateAction {
    /**
     * Always require review for matching order items
     */
    REQUIRE_REVIEW = 'REQUIRE_REVIEW',
    /**
     * Automatically create any new order items as a new item in the POS
     */
    CREATE_AUTOMATICALLY = 'CREATE_AUTOMATICALLY',
    /**
     * @deprecated
     */
    NOT_SELECTED_YET = 'NOT_SELECTED_YET',
  }

  export enum DefaultCurrencyConversionAction {
    DEFAULT_CURRENCY_CONVERSION_ACTION_UNKNOWN = 'DEFAULT_CURRENCY_CONVERSION_ACTION_UNKNOWN',
    /**
     * Always convert faire currency to pos currency
     */
    AUTO_CONVERT = 'AUTO_CONVERT',
    /**
     * Do not send price to pos side
     */
    DO_NOT_CONVERT = 'DO_NOT_CONVERT',
  }

  export enum AutoPublishAction {
    AUTO_PUBLISH_ACTION_UNKNOWN = 'AUTO_PUBLISH_ACTION_UNKNOWN',
    /**
     * Do not publish on the POS Integration *
     */
    DO_NOT_PUBLISH = 'DO_NOT_PUBLISH',
    /**
     * Automatically publish to POS integration when available *
     */
    AUTOMATICALLY_PUBLISH = 'AUTOMATICALLY_PUBLISH',
  }

  export enum SkuMatching {
    SKU_MATCHING_UNKNOWN = 'SKU_MATCHING_UNKNOWN',
    /**
     * Automatically find SKU-matched items and link them
     */
    AUTOMATIC_SKU_MATCHING = 'AUTOMATIC_SKU_MATCHING',
    /**
     * User needs to manually trigger the SKU matching
     */
    MANUAL_SKU_MATCHING = 'MANUAL_SKU_MATCHING',
    /**
     * Default setting before sku matching setting is set to show the first-time banner
     */
    NOT_SET = 'NOT_SET',
  }

  /**
   * Return an instance of IRetailerPosLinkSettings with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IRetailerPosLinkSettings>
  ): IRetailerPosLinkSettings =>
    ({
      ...(partial ?? {}),
    }) as IRetailerPosLinkSettings;
}

export interface IRetailerPosLinkSettings {
  /**
   * Options for new items we create in the POS.
   */
  new_item_settings: IRetailerPosLinkSettings.INewItemSettings | undefined;
  inventory_update:
    | keyof typeof IRetailerPosLinkSettings.InventoryUpdate
    | undefined;
  default_create_action:
    | keyof typeof IRetailerPosLinkSettings.DefaultCreateAction
    | undefined;
  selected_location: IPosLocation | undefined;
  prev_selected_location: IPosLocation | undefined;
  pull_only: boolean | undefined;
  /**
   * @deprecated
   */
  default_currency_conversion_action:
    | keyof typeof IRetailerPosLinkSettings.DefaultCurrencyConversionAction
    | undefined;
  auto_publish_action:
    | keyof typeof IRetailerPosLinkSettings.AutoPublishAction
    | undefined;
  sku_matching: keyof typeof IRetailerPosLinkSettings.SkuMatching | undefined;
  product_language: keyof typeof Language | undefined;
}
