import type { Span, Attributes, SpanOptions } from "@opentelemetry/api";

import type { ITelemetryHeaders, ITelemetryManager } from "./TelemetryManager";

/**
 * TelemetryManager is a singleton class that manages the OpenTelemetry
 * provider and tracer. It provides methods to start and end traces and spans,
 * and to get the headers for the active trace or span.
 * It also provides methods to start, end, and get headers for managed spans.
 * Managed spans are spans that are created by the TelemetryManager
 * and are not directly associated with the current context.
 */
export class TelemetryManagerNoOp implements ITelemetryManager {
  initialize = (_serviceName: string, _consoleProvider?: boolean) => {
    return;
  };

  startTrace = (
    _name: string,
    _attributes?: Attributes,
    _options?: SpanOptions,
    _useSerializedTraceContext?: boolean
  ): Span | undefined => {
    return;
  };

  endTrace = (_name?: string, _attributes?: Attributes) => {
    return;
  };

  getActiveTraceHeaders = (): ITelemetryHeaders | undefined => {
    return;
  };

  getActiveTraceId = (): string | undefined => {
    return;
  };

  startSpan = (
    _name: string,
    _attributes?: Attributes,
    _parentSpan?: Span,
    _root?: boolean
  ): undefined => {
    return;
  };

  endSpan = (span: Span | undefined) => {
    span?.end();
  };

  startManagedSpan = (
    _name: string,
    _attributes?: Attributes,
    _options?: SpanOptions
  ): string | undefined => {
    return;
  };

  endManagedSpan = (_spanId: string, _attributes?: Attributes): void => {};

  getManagedSpan = (_spanId: string): Span | null => {
    return null;
  };

  getSpanRequestHeaders = (_span?: string | Span): undefined => {
    return;
  };
}
