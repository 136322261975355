import { HEADER_SUBDOMAIN_OVERRIDE } from "@faire/web--source/common/consts/HEADER_SUBDOMAIN_OVERRIDE";
import { replaceSubdomain } from "@faire/web--source/common/replaceSubdomain";
import { getLocationOrigin } from "@faire/web--source/common/splitHostname";
import { isError } from "@faire/web--source/common/typescriptUtils";
import { IApiError } from "@faire/web-api--source/indigofair/data/IApiError";
import {
  NormalizedRequestHandler,
  RequestOptions,
} from "@faire/web-api--source/types";
import { Response as WebApiResponse } from "@faire/web-api--source/types";
import { WebApiError } from "@faire/web-api--source/WebApiError";
import { WebApiNetworkError } from "@faire/web-api--source/WebApiNetworkError";
import { WebApiRequestCancellationError } from "@faire/web-api--source/WebApiRequestCancellationError";

import { parseJson } from "../../string";

import { prefetchReaderFactory } from "./__internal__/prefetchReaderFactory";

const HTML_TAG_REGEXP = /<\s*html\s*>/i;

export const fetchRequestHandlerFactory = (
  customFetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>
): NormalizedRequestHandler => {
  return async (requestOptions: RequestOptions) => {
    let url = new URL(
      requestOptions.url,
      requestOptions.baseUrl ?? getLocationOrigin()
    );

    let body = requestOptions.data;

    const headers = new Headers(requestOptions.headers);
    if (
      typeof body === "object" &&
      headers.get("content-type") === "application/json"
    ) {
      body = JSON.stringify(body);
    }

    if (
      body instanceof FormData &&
      headers.get("content-type") === "multipart/form-data"
    ) {
      // Clear the content-type when sending form data over fetch
      // This stack overflow answer explains that the browser handles for us
      // https://stackoverflow.com/questions/35192841/how-do-i-post-with-multipart-form-data-using-fetch
      headers.delete("content-type");
    }

    let includeCredentials = requestOptions.withCredentials;

    if (requestOptions.subdomain && requestOptions.subdomain !== "www") {
      url = new URL(
        url.href.replace(url.origin, ""),
        replaceSubdomain(url.origin, requestOptions.subdomain)
      );
      // This header is required by the local dev proxy to override the subdomain
      headers.set(HEADER_SUBDOMAIN_OVERRIDE, requestOptions.subdomain);
      includeCredentials = includeCredentials ?? true;
    }

    if (requestOptions.params) {
      url.search = buildParams(url.search, requestOptions.params);
    }

    let resp: Response;
    try {
      let credentials: RequestCredentials = "omit";
      if (includeCredentials === undefined) {
        credentials = "same-origin";
      } else if (includeCredentials) {
        credentials = "include";
      }
      const requestInit = {
        method: requestOptions.method,
        body,
        headers,
        signal: requestOptions.signal,
        credentials,
        cache: requestOptions.cache,
        redirect: requestOptions.redirect,
        next: requestOptions.next,
      } as RequestInit;
      const fetchFn = customFetch ?? fetch;
      resp = await fetchFn(url.toString(), requestInit);
    } catch (e) {
      if (isError(e) && e.name === "AbortError") {
        return {
          error: new WebApiRequestCancellationError(),
        };
      }
      const defaultError = new WebApiNetworkError(`Network Error`);
      if (isError(e)) {
        defaultError.name = e.name;
        defaultError.message = `Network Error Fetching ${requestOptions.url}: ${e.message}`;
        defaultError.stack = e.stack;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultError.cause = e.cause;
      }
      return {
        error: defaultError,
      };
    }

    return normalizeResponse(resp, requestOptions);
  };
};

/**
 * Normalizes the response from the fetch request to the expected WebApiResponse
 */
export const normalizeResponse = async <T>(
  resp: Response,
  requestOptions: RequestOptions
): Promise<WebApiResponse<T>> => {
  const httpStatusCode = resp.status;
  const statusText = resp.statusText;
  const responseHeaders = Object.fromEntries(resp.headers.entries());
  const validRedirect =
    requestOptions.redirect === "manual" &&
    resp.status >= 300 &&
    resp.status < 400;
  const isRawResponse = requestOptions.rawResponse === true;
  const shouldError = !isRawResponse && !validRedirect && !resp.ok;

  if (shouldError) {
    const requestInfo = `${
      requestOptions.method
    } ${resp.url.toString()} ${httpStatusCode}: ${statusText}`;
    const responseText = await resp.text();

    let json: Partial<IApiError> | undefined;
    if (resp.headers.get("content-type")?.startsWith("application/json")) {
      try {
        json = parseJson(responseText) as IApiError;
      } catch {
        const errorMessage =
          resp.headers.get("content-type") === "text/html" ||
          HTML_TAG_REGEXP.test(responseText)
            ? undefined
            : responseText;
        json = {
          message: errorMessage,
        };
      }
    }

    return {
      status: httpStatusCode,
      headers: responseHeaders,
      error: new WebApiError(
        `${requestInfo}${json?.message ? `: ${json.message}` : ""}`,
        httpStatusCode,
        IApiError.build({
          ...json,
          status_code: httpStatusCode,
          status_type: statusText,
        })
      ),
    };
  }

  return {
    data: await handleResponseType(resp, requestOptions),
    status: httpStatusCode,
    headers: responseHeaders,
  };
};

const fetchHandler = fetchRequestHandlerFactory();

const handleResponseType = async <T>(
  resp: Response,
  requestOptions: RequestOptions
): Promise<T> => {
  let data: T = undefined as unknown as T;
  if (requestOptions.responseType === "json") {
    data = JSON.parse(await resp.text());
  } else if (requestOptions.responseType === "text") {
    data = (await resp.text()) as unknown as T;
  } else if (requestOptions.responseType === "blob") {
    data = (await resp.blob()) as unknown as T;
  } else if (resp.headers.get("content-type")?.startsWith("application/json")) {
    const text = await resp.text();
    try {
      if (text.length > 0) {
        data = JSON.parse(text);
      } else {
        // Empty string is not valid JSON, so we need to return an empty object
        data = {} as unknown as T;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Failed to parse JSON: ${e}`, {
        response: {
          status: resp.status,
          statusText: resp.statusText,
          method: requestOptions.method,
          fetchTarget: resp.url,
        },
      });
      data = text as unknown as T;
    }
  }
  return data;
};

const buildParams = (existingSearch: string, params?: any) => {
  const search = new URLSearchParams(existingSearch);
  for (const [k, v] of Object.entries(params)) {
    if (v === undefined) {
      continue;
    }
    const all = Array.isArray(v) ? v : [v];
    for (const each of all) {
      search.append(k, each);
    }
  }
  return search.toString();
};

export const requestHandler = prefetchReaderFactory(fetchHandler);
