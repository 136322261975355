import { DEFAULT_CURRENCY } from "@faire/retailer-visitor-shared/consts/DEFAULT_CURRENCY";
import { useSettingMigrateRetailerStore } from "@faire/retailer-visitor-shared/settings/useSettingMigrateRetailerStore";
import { useRetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";

import { useRetailer } from "./useRetailer";

const useRetailerFinancialControl = () => {
  const { faireCredit, currency } = useRetailerStore([
    "faireCredit",
    "currency",
  ]);

  return { faireCredit, currency };
};

const useRetailerFinancialTreatment = () => {
  const { retailer } = useRetailer();
  const faireCredit = (retailer && retailer.available_balance_cents) || 0;
  const currency = retailer?.currency ?? DEFAULT_CURRENCY;

  return { faireCredit, currency };
};

export const useRetailerFinancial = () => {
  const shouldUseHooksMigration = useSettingMigrateRetailerStore();
  if (shouldUseHooksMigration) {
    // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
    return useRetailerFinancialTreatment();
  }
  // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
  return useRetailerFinancialControl();
};
