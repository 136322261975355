import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';

/**
 * Generated from Jersey path "/user/brand-referrals"
 *
 * Product Area: USERS
 */
export const route = '/user/brand-referrals';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/user/brand-referrals',
  productArea: 'USERS',
  pod: 'FAIRE_POD_SECURITY',
  pillar: 'FAIRE_PILLAR_EPDD_FOUNDATIONS',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path() {
  const compiledPath = toPath({});
  return compiledPath;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (): string => {
  return faireBaseUrl(3000) + path();
};

export default path;
