import { clientReloadInterceptor } from "@faire/web--source/common/requests/response-interceptors/clientReloadInterceptor";
import { clientSideLatencyResponseInterceptor } from "@faire/web--source/common/requests/response-interceptors/clientSideLatencyResponseInterceptor";
import { errorLoggingInterceptor } from "@faire/web--source/common/requests/response-interceptors/errorLoggingInterceptor";
import { opentelemetryResponseInterceptor } from "@faire/web--source/common/requests/response-interceptors/opentelemetryResponseInterceptor";
import { ResponseInterceptor } from "@faire/web-api--source/types";

import { darkReadsResponseInterceptor } from "@faire/retailer-visitor-shared/initialize/dark-read/darkReadsResponseInterceptor";

export const clientResponseInterceptors: ReadonlyArray<ResponseInterceptor> =
  Object.freeze([
    errorLoggingInterceptor,
    clientReloadInterceptor,
    darkReadsResponseInterceptor,
    opentelemetryResponseInterceptor,
    clientSideLatencyResponseInterceptor,
  ]);
