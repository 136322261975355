import { SettingConst } from '@faire/web-api--source/types';

/**
 *   Map of conversion event name to the twitter conversion event ID. Ex. Purchase to ofgt1.
 *   Possible event names are listed in FacebookPixelSettings.PETE_FACEBOOK_CONVERSIONS_ALLOW_LIST_220406
 */
export const TWITTER_EVENT_NAME_TO_ID =
  'TWITTER_EVENT_NAME_TO_ID' as SettingConst<
    'TWITTER_EVENT_NAME_TO_ID',
    Partial<Record<string, string>>,
    'MAKER' | 'RETAILER' | 'VISITOR_WEB'
  >;
