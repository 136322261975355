import { fetchShouldDisplayResetPasswordAfterLogin } from "@faire/retailer-visitor-shared/components/AppSignIn/useShouldDisplayResetPasswordAfterLogin";
import { isLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { History } from "history";
import qs from "query-string";

export const initializeResetPasswordModal = async (history: History) => {
  const shouldShowModal = await fetchShouldDisplayResetPasswordAfterLogin();
  if (shouldShowModal && isLoggedInRetailer()) {
    //TODO: add event tracking
    history.replace({
      ...history.location,
      search: qs.stringify({
        ...qs.parse(history.location.search),
        resetPassword: 1,
      }),
    });
  }
};
