import { request } from '@faire/web-api--source';
import { IGetPosLocationsForRetailerResponse } from '@faire/web-api--source/indigofair/retailers/pos/IGetPosLocationsForRetailerResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/retailers-pos-api/pos-locations`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/retailers-pos-api/pos-locations';

/**
 * Product Area: RETAILER_POS_INTEGRATIONS
 */
function execute(): Promise<IGetPosLocationsForRetailerResponse>;
function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetPosLocationsForRetailerResponse>>;
function execute(
  options?: EndpointOptions
): Promise<IGetPosLocationsForRetailerResponse>;
function execute(
  options?: EndpointOptions
): Promise<
  | IGetPosLocationsForRetailerResponse
  | SuccessResponse<IGetPosLocationsForRetailerResponse>
> {
  const { route, ...requestOptions } = build(options);
  return request<IGetPosLocationsForRetailerResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/retailers-pos-api/pos-locations/get';
export default execute;
