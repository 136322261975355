import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';

/**
 * Generated from Jersey path "/retailer/settings/pos-manager"
 *
 * Product Area: RETAILER_SETTINGS_PAGE
 */
export const route = '/retailer/settings/pos-manager';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/retailer/settings/pos-manager',
  productArea: 'RETAILER_SETTINGS_PAGE',
  pod: 'FAIRE_POD_UPMARKET_TOOLING',
  pillar: 'FAIRE_PILLAR_RETAILER_TOOLS_AND_PROGRAMS',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path() {
  const compiledPath = toPath({});
  return compiledPath;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (): string => {
  return faireBaseUrl(3000) + path();
};

export default path;
