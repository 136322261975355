import { getShouldRequestRSCOnBrandPage } from "@faire/retailer-visitor-shared/app/_lib/routing/__internal__/getShouldRequestRSCOnBrandPage";
import { getShouldRequestRSCOnCategoryPage } from "@faire/retailer-visitor-shared/app/_lib/routing/__internal__/getShouldRequestRSCOnCategoryPage";
import { getShouldRequestRSCOnSearchPage } from "@faire/retailer-visitor-shared/app/_lib/routing/__internal__/getShouldRequestRSCOnSearchPage";
import { isPathnameEqual } from "@faire/retailer-visitor-shared/app/_lib/routing/__internal__/isPathnameEqual";

export function getShouldRequestRSC(
  prevLocation: { pathname: string; search: string },
  updatedLocation: { pathname: string; search: string }
) {
  const isSamePage = isPathnameEqual(
    prevLocation.pathname,
    updatedLocation.pathname
  );

  if (!isSamePage) {
    return true;
  }

  const shouldRequestRSCOnSearchPage = getShouldRequestRSCOnSearchPage({
    prevPathname: prevLocation.pathname,
    prevSearch: prevLocation.search,
    pathname: updatedLocation.pathname,
    search: updatedLocation.search,
  });
  const shouldRequestRSCOnBrandPage = getShouldRequestRSCOnBrandPage({
    prevSearch: prevLocation.search,
    pathname: updatedLocation.pathname,
    search: updatedLocation.search,
  });

  const shouldRequestRSCOnCategoryPage = getShouldRequestRSCOnCategoryPage({
    prevPathname: prevLocation.pathname,
    prevSearch: prevLocation.search,
    pathname: updatedLocation.pathname,
    search: updatedLocation.search,
  });

  return (
    shouldRequestRSCOnSearchPage ||
    shouldRequestRSCOnBrandPage ||
    shouldRequestRSCOnCategoryPage
  );
}
