import { request } from '@faire/web-api--source';
import { IIsAppInstalledResponse } from '@faire/web-api--source/faire/page_data/IIsAppInstalledResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/page-data/is-app-installed`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/page-data/is-app-installed';

/**
 * Product Area: RETAILER_ONBOARDING
 */
function execute(): Promise<IIsAppInstalledResponse>;
function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IIsAppInstalledResponse>>;
function execute(options?: EndpointOptions): Promise<IIsAppInstalledResponse>;
function execute(
  options?: EndpointOptions
): Promise<IIsAppInstalledResponse | SuccessResponse<IIsAppInstalledResponse>> {
  const { route, ...requestOptions } = build(options);
  return request<IIsAppInstalledResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/page-data/is-app-installed/get';
export default execute;
