import capitalize from "lodash/capitalize";
import words from "lodash/words";

import { isNotUndefined } from "./typescriptUtils";

export namespace NonBreaking {
  export const space = "\u00A0";
  export const hyphen = "\u2011";
}

export const preventOrphanWords = (input: string): string => {
  const hyphenedInput = input.replace(/-/g, NonBreaking.hyphen);
  const trimedInput = hyphenedInput.trim();
  const lastSpace = trimedInput.lastIndexOf(" ");
  if (lastSpace === -1) {
    return trimedInput;
  }

  return (
    trimedInput.slice(0, lastSpace) +
    NonBreaking.space +
    trimedInput.slice(lastSpace + 1, trimedInput.length)
  );
};

export const isVowel = (x: string): boolean => /[aeiouAEIOU]/.test(x);

/**
 * @deprecated: use localize() instead. See {@link https://www.notion.so/faire/formatjs-language-localization-a526c0050c234984bfa9fce47b485d43}
 */
export const pluralize = (text: string, value: number) => {
  const lastCharacter = text[text.length - 1]?.toLowerCase();
  if (!text || lastCharacter === "s" || value === 1) {
    return text;
  }
  return `${text}s`;
};

/**
 * Returns an integer hashCode for the given string.
 * Implementation is taken from Java's `String#hashCode`.
 */
export const hashCode = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const titleCaseWithSpecialCharacters = (input: string): string =>
  words(input, /[^, ]+/g)
    .map((word: string) => capitalize(word))
    .join(" ");

export const capitalizeEveryWord = (
  str: string,
  skipLowercase?: boolean
): string => {
  if (!str) {
    return str;
  }

  let intermediate = str;
  if (!skipLowercase) {
    intermediate = intermediate.toLowerCase();
  }

  return intermediate.replace(/(^|[\s-]|\.)\w/g, (letter) =>
    letter.toUpperCase()
  );
};

/**
 * Returns object with `unknown` type to enforce explicit type casting after parsing strings.
 * This is beneficial as `JSON.parse` returns `any` which reduces type safety after use.
 */
export const parseJson = (input: string): unknown => JSON.parse(input);

/**
 * Splits a string into chunks of a maximum length, rounded (down) to the nearest newline.
 */
export const splitChunksByNearestNewline = (
  text: string,
  maxChunkLength: number
): Array<string> => {
  const lines = text.split("\n");
  const chunks = [];
  let chunk = undefined;
  let totalLength = 0;
  for (const line of lines) {
    const lineLength = line.length;
    if (totalLength + lineLength > maxChunkLength) {
      chunks.push(chunk);
      chunk = line;
      totalLength = lineLength;
    } else {
      chunk = chunk ? chunk + "\n" + line : line;
      totalLength += lineLength;
    }
  }
  if (chunk) {
    chunks.push(chunk);
  }
  return chunks.filter(isNotUndefined);
};
