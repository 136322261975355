import { isLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import {
  getSettingRscLoggedInSearchPage,
  getSettingRscLoggedInSearchPageWithSDUI,
} from "@faire/retailer-visitor-shared/settings/getSettingRscLoggedInSearchPage";
import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { QueryParameters as SearchQueryParameters } from "@faire/web-api--source/routes/www/search";

export const shouldShowRscLoggedInSearchPage = () => {
  const search = getLocationOrThrow().search;
  const rscParam = (
    SearchQueryParameters.parse(search) as Record<string, string | undefined>
  ).rsc;
  return getSettingRscLoggedInSearchPage(rscParam) && isLoggedInRetailer();
};

export const shouldShowRscLoggedInSearchPageWithSDUI = () => {
  const search = getLocationOrThrow().search;
  const rscParam = (
    SearchQueryParameters.parse(search) as Record<string, string | undefined>
  ).rsc;
  return (
    getSettingRscLoggedInSearchPageWithSDUI(rscParam) && isLoggedInRetailer()
  );
};
