import { SettingConst } from '@faire/web-api--source/types';

/**
 * Controls whether or not we render the search page components on the server using SDUI renderers
 */
export const NEXTJS_RSC_SDUI_LOGGED_IN_SEARCH_PAGE_250122 =
  'NEXTJS_RSC_SDUI_LOGGED_IN_SEARCH_PAGE_250122' as SettingConst<
    'NEXTJS_RSC_SDUI_LOGGED_IN_SEARCH_PAGE_250122',
    boolean,
    'RETAILER' | 'RETAILER_HEAD_TAGS' | 'VISITOR_WEB' | 'VISITOR_WEB_HEAD_TAGS'
  >;
