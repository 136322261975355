import { trackClientEvent } from '@faire/web-api--source/trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'frontend_apis-release_reloaded-action_unknown';

/**
 * Event emitted when a release is reloaded due to being flagged for needing a reload
 */
export function trackFrontendApisReleaseReloaded(): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: true,
  });
}
