"use client";

// This must be first, because it sets up the global lookup.
// eslint-disable-next-line import/no-unassigned-import
import "@faire/retailer-visitor-shared/app/_lib/initialization/clientComponentSSR";

import { Globals } from "@faire/retailer-visitor-shared/app/_lib/Globals";
import { AppInitializer } from "@faire/retailer-visitor-shared/app/_lib/initialization/AppInitializer";
import { ReactQueryDevtools } from "@faire/retailer-visitor-shared/app/_lib/ReactQueryDevtools";
import { ReactScanMonitoring } from "@faire/retailer-visitor-shared/app/_lib/ReactScanMonitoring";
import { NextRouterContextProvider } from "@faire/retailer-visitor-shared/app/_lib/routing/NextRouterContext";
import { ReactRouterCompat } from "@faire/retailer-visitor-shared/app/_lib/routing/ReactRouterCompat";
import { useMemoryHistory } from "@faire/retailer-visitor-shared/app/_lib/routing/useMemoryHistory";
import { AppDownloadWrapper } from "@faire/retailer-visitor-shared/app/_lib/wrappers/AppDownloadWrapper";
import { AppEffects } from "@faire/retailer-visitor-shared/components/App/AppEffects";
import { DevTools } from "@faire/retailer-visitor-shared/components/App/DevTools";
import { HCaptcha } from "@faire/retailer-visitor-shared/components/AppSignIn/HCaptcha";
import { FirstOrderIncentiveFabWrapper } from "@faire/retailer-visitor-shared/components/FirstOrderIncentiveFab/FirstOrderIncentiveFabWrapper";
import { AddedToBagContextStoreProvider } from "@faire/retailer-visitor-shared/components/Notifications/AddedToBag/AddedToBagStore";
import { RetailerRoutingContextProvider } from "@faire/retailer-visitor-shared/RetailerRoutingContextProvider";
import { getSettingsValues } from "@faire/retailer-visitor-shared/serialized-data/getSettingsValues";
import { ReactQueryClientStoreProvider } from "@faire/retailer-visitor-shared/services/ReactQueryClient";
// eslint-disable-next-line @faire/enforce-internal-folder-encapsulation
import { SettingsContextProvider } from "@faire/retailer-visitor-shared/settings/__internal__/useSetting";
import { HCaptchaStoreProvider } from "@faire/retailer-visitor-shared/stores/domain/HCaptchaStore";
import { OpeningOrderIncentivesProvider } from "@faire/retailer-visitor-shared/stores/domain/OpeningOrderIncentivesProvider";
import { SSOContextStoreProvider } from "@faire/retailer-visitor-shared/stores/domain/SSO/SSOContextStore";
import { AppSnackbarProvider } from "@faire/retailer-visitor-shared/stores/ui/AppSnackbarStore";
import { FreeShippingContextStoreProvider } from "@faire/retailer-visitor-shared/stores/ui/FreeShippingContextStore";
import { PromoModalContextStoreProvider } from "@faire/retailer-visitor-shared/stores/ui/PromoModalContextStore";
import { TopOfPageContextStoreProvider } from "@faire/retailer-visitor-shared/stores/ui/TopOfPageContextStore";
import { DeferredRenderContextProvider } from "@faire/web--source/common/deferredRender";
import { IntlProvider } from "@faire/web--source/common/localization/IntlProvider";
import { LocalizationProvider } from "@faire/web--source/common/localization/useLocalization";
import { NoSSR } from "@faire/web--source/common/NoSSR";
import { QueryClientProvider } from "@faire/web--source/common/reactQuery";
import { DefaultTheme } from "@faire/web--source/slate/Theme";
import { ThemeProvider } from "@faire/web--source/slate/Theme/ThemeProvider";
import { ObservableWindowStoreProvider } from "@faire/web--source/ui/hooks/useObservableWindow";
import { MemoryHistory } from "history";
import React, { useMemo } from "react";

import { ClientViewport } from "./headers/ClientViewport";
import { NextCacheTrackingContextProvider } from "./routing/NextCacheTrackingContextProvider";

type PublicApiKeys = {
  REACT_SCAN_MONITORING_API_KEY: string | undefined;
};

type BaseProps = {
  children: React.ReactNode;
  memoryHistory: MemoryHistory;
  publicApiKeys: PublicApiKeys;
};

const BaseProviders = ({
  children,
  memoryHistory,
  publicApiKeys,
}: BaseProps) => {
  const settings = useMemo(() => getSettingsValues(), []);
  return (
    <SettingsContextProvider settings={settings}>
      <NextCacheTrackingContextProvider>
        <RetailerRoutingContextProvider>
          <NextRouterContextProvider>
            <LocalizationProvider>
              <ReactRouterCompat memoryHistory={memoryHistory}>
                <ReactScanMonitoring
                  apiKey={publicApiKeys.REACT_SCAN_MONITORING_API_KEY}
                />
                <ClientViewport />
                <ObservableWindowStoreProvider>
                  <ThemeProvider theme={DefaultTheme}>
                    <QueryClientProvider>
                      <ReactQueryClientStoreProvider>
                        <FreeShippingContextStoreProvider>
                          <TopOfPageContextStoreProvider>
                            <HCaptchaStoreProvider>
                              <AddedToBagContextStoreProvider>
                                <AppSnackbarProvider>
                                  <IntlProvider>
                                    <SSOContextStoreProvider>
                                      <PromoModalContextStoreProvider>
                                        <OpeningOrderIncentivesProvider>
                                          {children}
                                          <AppEffects />
                                          <NoSSR>
                                            <HCaptcha />
                                          </NoSSR>
                                          <AppDownloadWrapper />
                                          <FirstOrderIncentiveFabWrapper />
                                          <DevTools />
                                        </OpeningOrderIncentivesProvider>
                                      </PromoModalContextStoreProvider>
                                    </SSOContextStoreProvider>
                                  </IntlProvider>
                                </AppSnackbarProvider>
                              </AddedToBagContextStoreProvider>
                            </HCaptchaStoreProvider>
                          </TopOfPageContextStoreProvider>
                        </FreeShippingContextStoreProvider>
                      </ReactQueryClientStoreProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                  </ThemeProvider>
                </ObservableWindowStoreProvider>
              </ReactRouterCompat>
            </LocalizationProvider>
          </NextRouterContextProvider>
        </RetailerRoutingContextProvider>
      </NextCacheTrackingContextProvider>
    </SettingsContextProvider>
  );
};

type Props = {
  children: React.ReactNode;
  userAgent: string | null;
  referer: string | null;
  globals: Record<string, unknown>;
  originalUrl: string | undefined;
  publicApiKeys: PublicApiKeys;
};

export const Providers = ({
  children,
  userAgent,
  referer,
  globals,
  originalUrl,
  publicApiKeys,
}: Props) => {
  const memoryHistory = useMemoryHistory();
  return (
    <DeferredRenderContextProvider>
      <AppInitializer history={memoryHistory} />
      <Globals
        globals={globals}
        userAgent={userAgent}
        referer={referer}
        memoryHistory={memoryHistory}
        originalUrl={originalUrl}
      >
        <BaseProviders
          memoryHistory={memoryHistory}
          publicApiKeys={publicApiKeys}
        >
          {children}
        </BaseProviders>
      </Globals>
    </DeferredRenderContextProvider>
  );
};
