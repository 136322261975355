import { logHCaptchaError } from "@faire/retailer-visitor-shared/lib/captchaUtils";
import { isLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { getSettingHCaptchaKey } from "@faire/retailer-visitor-shared/settings/getSettingHCaptchaKey";
import { useHCaptchaStore } from "@faire/retailer-visitor-shared/stores/domain/HCaptchaStore";
import { getDocument } from "@faire/web--source/common/globals/getDocument";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { loadScript } from "@faire/web--source/common/loadScript";
import {
  trackSigninHcaptchaInit,
  trackSigninHcaptchaInitTraceDataRequested,
  trackSigninHcaptchaInitTraceDataRetrieved,
  trackSigninHcaptchaInitTraceRetrievalFailed,
} from "@faire/web-api--source/events/signin/actionUnknown/hcaptchaInit";
import { trackSigninHcaptchaRenderIsNotFunctionError } from "@faire/web-api--source/events/signin/error/hcaptchaRenderIsNotFunction";
import { ILoginRequest } from "@faire/web-api--source/indigofair/data/ILoginRequest";
import React, { FC, useEffect, useRef, useState } from "react";

/**
 * Initializes HCpatcha and renders the invisible HCaptcha widget when:
 * - the session is not logged-in
 * - isHCaptchaEnabled is true
 *
 * isHCaptchaEnabled is set to true by hCaptcha callers (e.g. the sign-in form). The widget
 * is put high in the React tree (App.tsx) so it is not unmounted and remounted multiple
 * times in a session – this would consume more quota than necessary and cause unexpected
 * behaviours.
 */
export const HCaptcha: FC = () => {
  const ref = useRef(null);
  const [{ widgetID: savedWidgetId, isHCaptchaEnabled }, setHCaptchaStore] =
    useHCaptchaStore(["widgetID", "isHCaptchaEnabled"]);

  // There are cases when the effect is run before the library is initialized,
  // which would result in `hcaptcha.render` to be unavailable. By re-rendering
  // once the library is initialized, we can ensure that the widget is rendered.
  const [hasBeenInitialized, setHasBeenInitialized] = useState(
    isHCaptchaInitialized()
  );

  const shouldGetWidgetId =
    isHCaptchaEnabled && !savedWidgetId && hasBeenInitialized;

  useEffect(() => {
    initializeHCaptcha(() => setHasBeenInitialized(true));
  }, []);

  useEffect(() => {
    if (shouldGetWidgetId && ref.current) {
      let widgetID: string | undefined;
      try {
        widgetID = getWidgetId(ref.current);
      } catch {
        // error is inferred if widgetID is undefined
      }

      setHCaptchaStore({
        widgetID,
        initializationError: widgetID
          ? undefined
          : ILoginRequest.IHCaptchaData.HCaptchaError.RENDER_FAIL,
      });
    }
  }, [shouldGetWidgetId, setHCaptchaStore]);

  return <div ref={ref} style={{ display: "none" }} />;
};

const HCAPTCHA_SCRIPT_NAME = "_hCaptcha";
const HCAPTCHA_SCRIPT_SRC = "https://js.hcaptcha.com/1/api.js?render=explicit";

const isHCaptchaInitialized = () =>
  !!getDocument()?.getElementById(HCAPTCHA_SCRIPT_NAME);

const initializeHCaptcha = async (onInit: () => void) => {
  if (isLoggedInRetailer()) {
    return;
  }
  trackSigninHcaptchaInit();
  if (!isHCaptchaInitialized()) {
    try {
      trackSigninHcaptchaInitTraceDataRequested();
      await loadScript(HCAPTCHA_SCRIPT_SRC, HCAPTCHA_SCRIPT_NAME);
      trackSigninHcaptchaInitTraceDataRetrieved();
    } catch (error) {
      logHCaptchaError(
        error,
        ILoginRequest.IHCaptchaData.HCaptchaError.NO_SCRIPT
      );
      trackSigninHcaptchaInitTraceRetrievalFailed();
    } finally {
      // we wanna signal that the library has been initialized, regardless of the outcome
      // subsequent checks are made to ensure that the library is available
      onInit();
    }
  }
};

const getWidgetId = (container: HTMLElement): string => {
  const renderHCaptcha = getGlobalProperty<HCaptcha | undefined>(
    "hcaptcha"
  )?.render;

  if (!(typeof renderHCaptcha === "function")) {
    trackSigninHcaptchaRenderIsNotFunctionError();
    throw new Error(ILoginRequest.IHCaptchaData.HCaptchaError.RENDER_FAIL);
  }

  const widgetID = renderHCaptcha(container, {
    sitekey: getSettingHCaptchaKey(),
    size: "invisible",
    callback: (_reponseToken) => {},
  });
  return widgetID;
};
