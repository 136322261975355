import {
  getAllowList,
  getOverrideLocaleHeaderAllowList,
} from "@faire/retailer-visitor-shared/initialize/dark-read/darkReadAllowList";
import { setIntervalIfPossible } from "@faire/retailer-visitor-shared/lib/async-functions/setIntervalIfPossible";
import { getSettingsValues } from "@faire/retailer-visitor-shared/serialized-data/getSettingsValues";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { logError } from "@faire/web--source/common/logging";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import { MIN_WIDTH_BREAKPOINTS } from "@faire/web--source/common/media";
import { isWindowUndefined } from "@faire/web--source/common/server-side-rendering/isWindowUndefined";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import fetchSetting, {
  QueryParameters as FetchSettingParams,
} from "@faire/web-api--source/endpoints/www/api/setting/public/get";
import { BRAND_SEARCH_DARK_READS } from "@faire/web-api--source/indigofair/settings/BRAND_SEARCH_DARK_READS";
import { IPerfDarkReadsConfig } from "@faire/web-api--source/indigofair/settings/IPerfDarkReadsConfig";
import { PERF_DARK_READ_TESTING_ENABLED } from "@faire/web-api--source/indigofair/settings/PERF_DARK_READ_TESTING_ENABLED";
import { PERF_DARK_READ_TESTING_OVERRIDE_LOCALE_HEADER } from "@faire/web-api--source/indigofair/settings/PERF_DARK_READ_TESTING_OVERRIDE_LOCALE_HEADER";
import { RequestOptions } from "@faire/web-api--source/types";

type DarkReadSetting =
  | typeof BRAND_SEARCH_DARK_READS
  | typeof PERF_DARK_READ_TESTING_ENABLED
  | typeof PERF_DARK_READ_TESTING_OVERRIDE_LOCALE_HEADER;

export class DarkReadManager {
  static get = singletonGetter(DarkReadManager);

  static localeOverride = () => LocalesOverrideDarkReadManager.get();

  private settingName: DarkReadSetting;

  private darkReadConfig: IPerfDarkReadsConfig | undefined;

  private updateDarkReadConfigsIntervalId: number = 0;

  constructor(settingName?: DarkReadSetting) {
    makeObservable(this);
    this.settingName = settingName ?? PERF_DARK_READ_TESTING_ENABLED;
    this.darkReadConfig = IPerfDarkReadsConfig.build(
      getSettingsValues()[this.settingName] as IPerfDarkReadsConfig
    );

    this.updateInterval();
  }

  get darkReadDelayInMs(): number {
    return (this.darkReadConfig?.dark_read_burst_delay_in_second ?? 3) * 1000;
  }

  /**
   * Returns true if dark reads are enabled.
   * Note that this does not factor in whether a given request should be performed
   * based on probability - see #shouldPerformDarkRead.
   */
  get canPerformDarkReads(): boolean {
    return !!this.darkReadConfig?.enabled && !isWindowUndefined();
  }

  // unused-class-members-ignore-next
  shouldPerformDarkRead = (): boolean => {
    // Check if we fall under the probability
    return Math.random() < (this.darkReadConfig?.probability_value ?? 1);
  };

  /**
   * Returns true if this dark reads config is enabled and applicable to the given request.
   */
  shouldMultiplyDarkReads = (requestOptions: RequestOptions): boolean => {
    if (!this.canPerformDarkReads || !this.shouldPerformDarkRead()) {
      return false;
    }

    try {
      const routeAllowList = getAllowList(requestOptions.method);
      const matchResult = routeAllowList?.match(requestOptions.url);

      if (!matchResult) {
        return false;
      }

      if (this.darkReadConfig?.endpoints?.length) {
        // Config is explicitly allowing a subset.
        if (
          !this.darkReadConfig.endpoints.some((e) => {
            try {
              return new RegExp(e).test(requestOptions.url);
            } catch (e) {
              logError(e);
              // Fall through
            }
            return false;
          })
        ) {
          return false;
        }
      }

      // there isn't a :something_token pattern, so it's an exact match
      if (
        matchResult.match.length === 1 ||
        !/\/:\w+_token($|\/)/.test(matchResult.route)
      ) {
        return true;
      }

      const tokenMatch = matchResult.match[1];

      // naive token test: it should be 1/2 letters + _ + letters or numbers
      return /^\w{1,2}_(\w|\d)+$/.test(tokenMatch ?? "");
    } catch {
      return false;
    }
  };

  /**
   * Returns true if the outgoing request has enabled locale overrides (i.e. performing the dark read in
   * a different locale than the outgoing request).
   */
  shouldOverrideLocaleHeader = (requestOptions: RequestOptions): boolean => {
    const routeAllowList = getOverrideLocaleHeaderAllowList(
      requestOptions.method
    );
    const matchResult = routeAllowList?.match(requestOptions.url);

    if (!matchResult) {
      return false;
    }

    // there isn't a :something_token pattern, so it's an exact match
    if (
      matchResult.match.length === 1 ||
      !/\/:\w+_token($|\/)/.test(matchResult.route)
    ) {
      return true;
    }

    const tokenMatch = matchResult.match[1];

    // naive token test: it should be 1/2 letters + _ + letters or numbers
    return /^\w{1,2}_(\w|\d)+$/.test(tokenMatch ?? "");
  };

  /**
   * Multiplication factor for the dark read config. Returns zero when not enabled.
   */
  get darkReadMultiplier() {
    if (!this.canPerformDarkReads) {
      return 0;
    }
    const windowWidth = getWindow()?.innerWidth ?? 0;
    return Math.min(
      this.darkReadConfig?.multiplication_value ?? 0,
      windowWidth < MIN_WIDTH_BREAKPOINTS.TABLET
        ? (this.darkReadConfig?.mobile_web_multiplication_value_cap ??
            MAX_DARK_READ_MULTIPLIER_MOBILE)
        : MAX_DARK_READ_MULTIPLIER
    );
  }

  private clearInterval = () => {
    if (this.updateDarkReadConfigsIntervalId) {
      clearInterval(this.updateDarkReadConfigsIntervalId);
    }
  };

  private updateInterval = () => {
    if (this.canPerformDarkReads) {
      this.updateDarkReadConfigsIntervalId =
        setIntervalIfPossible(
          this.refresh,
          (this.darkReadConfig?.next_pulling_delay_in_second ?? 10 * 60) * 1000
        ) ?? 0;
    }
  };

  refresh = async () => {
    const previousPollingInterval =
      this.darkReadConfig?.next_pulling_delay_in_second;
    try {
      const config = await fetchSetting(
        FetchSettingParams.build({
          setting: this.settingName,
        })
      );

      if (!config) {
        logError(`${this.settingName} has undefined dark-read config`);
      }
      this.darkReadConfig = config;
    } catch {
      // something went wrong... let's stop the dark reads
      this.darkReadConfig = undefined;
    }

    const intervalChanged =
      this.darkReadConfig?.next_pulling_delay_in_second !==
      previousPollingInterval;
    const disabled = !this.darkReadConfig?.enabled;
    if (disabled || intervalChanged) {
      this.clearInterval();
    }
    if (intervalChanged) {
      this.updateInterval();
    }
  };
}

class LocalesOverrideDarkReadManager extends DarkReadManager {
  static get = singletonGetter(LocalesOverrideDarkReadManager);

  constructor() {
    super(PERF_DARK_READ_TESTING_OVERRIDE_LOCALE_HEADER);
  }
}

const MAX_DARK_READ_MULTIPLIER = 10;
const MAX_DARK_READ_MULTIPLIER_MOBILE = 5;
