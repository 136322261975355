import { useIsOnCartPage } from "@faire/retailer-visitor-shared/hooks/cart/useIsOnCartPage";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import {
  buildServerCartQueryKey,
  useRelevantQueryParams,
  SERVER_CART_QUERY_KEY_PREFIX,
} from "./utils";

export const useInvalidateServerCart = () => {
  const queryClient = useQueryClient();
  const queryParams = useRelevantQueryParams();
  const isOnCartPage = useIsOnCartPage();

  const invalidateServerCart = useCallback(
    (savedForLater: boolean) => {
      if (isOnCartPage) {
        /**
         * When we're on the cart page, we can be agressive with refetching since
         * the retailer needs to see the most up to date cart data.
         */
        return queryClient.invalidateQueries({
          queryKey: buildServerCartQueryKey(queryParams, savedForLater, true),
          refetchType: "all",
        });
      } else {
        /**
         * When we're not on the cart page, we don't want the server action to refetch
         * at all since the request will fail. Instead we'll remove the query from the cache
         * so that the `initialData` from the server component is used instead.
         */
        queryClient.removeQueries({
          queryKey: [SERVER_CART_QUERY_KEY_PREFIX],
          exact: false,
        });
        return Promise.resolve();
      }
    },
    [queryClient, queryParams, isOnCartPage]
  );

  const invalidateActiveServerCart = useCallback(
    () => invalidateServerCart(false),
    [invalidateServerCart]
  );
  const invalidateSavedServerCart = useCallback(
    () => invalidateServerCart(true),
    [invalidateServerCart]
  );

  return { invalidateActiveServerCart, invalidateSavedServerCart };
};
