import {
  getDoQuerySpellcheckGivenRecordOfQueryParams,
  getFilterKeysGivenRecordOfQueryParams,
  getPageNumberGivenRecordOfQueryParams,
  getSearchQueryGivenRecordOfQueryParams,
} from "@faire/retailer-shared/search/searchRequest";
import { getSearchPageLoadKey } from "@faire/retailer-visitor-shared/nextjs-rsc-util/getSearchPageLoadKey";

export const buildSearchPageLoadKey = (
  parsedSearch: Record<string, string | undefined>
) => {
  const query = getSearchQueryGivenRecordOfQueryParams(parsedSearch);
  const filters = getFilterKeysGivenRecordOfQueryParams(parsedSearch);
  const pageNumber = getPageNumberGivenRecordOfQueryParams(parsedSearch);
  const doQuerySpellCheck =
    getDoQuerySpellcheckGivenRecordOfQueryParams(parsedSearch);
  return getSearchPageLoadKey({
    query,
    filters,
    pageNumber,
    doQuerySpellCheck,
  });
};
