import { delay } from "@faire/web--source/common/delay";
import { request as execute } from "@faire/web-api--source";
import { PERF_DARK_READ_TESTING_ENABLED } from "@faire/web-api--source/indigofair/settings/PERF_DARK_READ_TESTING_ENABLED";
import { ResponseInterceptor } from "@faire/web-api--source/types";
import clone from "lodash/clone";
import range from "lodash/range";

import { DarkReadManager } from "@faire/retailer-visitor-shared/initialize/dark-read/DarkReadManager";
import { getOverrideLocaleHeader } from "@faire/retailer-visitor-shared/initialize/dark-read/darkReadOverrideLocale";
import {
  HEADER_DARK_READ,
  HEADER_DARK_READ_ORIGINAL_REQUEST,
} from "@faire/retailer-visitor-shared/initialize/dark-read/HEADER_DARK_READ";

/**
 * Interceptor for replicating requests after a given request has been invoked,
 * if it has the HTTP header identifying it as the original.
 */
export const darkReadsResponseInterceptor: ResponseInterceptor = (
  originalRequest,
  response
) => {
  const isDarkRead =
    originalRequest.darkRead || originalRequest.headers?.[HEADER_DARK_READ];
  if (isDarkRead) {
    return response;
  }

  const shouldDarkRead =
    originalRequest.headers?.[HEADER_DARK_READ_ORIGINAL_REQUEST];
  if (!shouldDarkRead) {
    return response;
  }

  const darkReadRequestOptions = clone(originalRequest);
  let headers = darkReadRequestOptions.headers || {};
  delete headers[HEADER_DARK_READ_ORIGINAL_REQUEST];

  const manager = DarkReadManager.get();
  const shouldOverrideLocaleHeader = manager.shouldOverrideLocaleHeader(
    darkReadRequestOptions
  );
  if (shouldOverrideLocaleHeader) {
    headers = getOverrideLocaleHeader(darkReadRequestOptions);
  }
  headers[HEADER_DARK_READ] = PERF_DARK_READ_TESTING_ENABLED;
  darkReadRequestOptions.headers = headers;

  // Otherwise, invoke the original request and schedule dark reads for later.
  delay(manager.darkReadDelayInMs).then(() => {
    range(manager.darkReadMultiplier).forEach(() => {
      execute(darkReadRequestOptions).catch(() => {
        // Ignore errors on dark reads.
      });
    });
  });
  return response;
};
