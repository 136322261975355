import { RetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";
import { UserStore } from "@faire/retailer-visitor-shared/stores/domain/UserStore";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { IBrand } from "@faire/web-api--source/indigofair/data/IBrand";
import { IUser } from "@faire/web-api--source/indigofair/data/IUser";

// fetch brand users "retailer" for 'View My Shop'
export const fetchBrandRetailer = async () => {
  const { user } = UserStore.get();
  const brand = getGlobalProperty<IBrand>("brand");
  if (!user || user.type !== IUser.Type.BRAND_USER || !brand?.token) {
    return;
  }

  //TODO: (@Jadesida) Remove once we've migrated RetailerStore to hooks fully
  await RetailerStore.get().refetchRetailer(`r_${brand.token}`);
};
