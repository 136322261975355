import {
  useAssignSetting,
  useSetting,
} from "@faire/retailer-visitor-shared/settings/__internal__/useSetting";
import { IMPROVED_FOI_EXPERIENCE_241029 } from "@faire/web-api--source/indigofair/settings/IMPROVED_FOI_EXPERIENCE_241029";

export const useSettingImprovedFoiExperience = (): boolean =>
  useSetting(IMPROVED_FOI_EXPERIENCE_241029, false);

export const useAssignSettingImprovedFoiExperience = () =>
  useAssignSetting(IMPROVED_FOI_EXPERIENCE_241029);
