import { isSocialBrowser } from "@faire/retailer-visitor-shared/lib/user-agent/isSocialBrowser";
import { isWebView } from "@faire/retailer-visitor-shared/lib/user-agent/isWebView";
import { Apple } from "@faire/web--source/slate/icons/external/Apple";
import { Google } from "@faire/web--source/slate/icons/external/Google";
import { Lock } from "@faire/web--source/slate/icons/foundation/Lock";
import { IImage } from "@faire/web-api--source/indigofair/data/IImage";
import { ISignUpSuggestions } from "@faire/web-api--source/indigofair/data/ISignUpSuggestions";
import { SocialSignOnProvider } from "@faire/web-api--source/indigofair/data/SocialSignOnProvider";
import React from "react";

export const PROFILE_IMAGE_SIZE = 64;

export const getSocialProviderText = (
  socialProvider: SocialSignOnProvider
): string => {
  switch (socialProvider) {
    case SocialSignOnProvider.GOOGLE:
      return "Google";
    case SocialSignOnProvider.APPLE:
      return "Apple";
    case SocialSignOnProvider.OKTA:
      return "Okta";
    default:
      return "";
  }
};

export const getSocialProviderLogo = (
  socialProvider: SocialSignOnProvider
): React.ReactNode => {
  switch (socialProvider) {
    case SocialSignOnProvider.GOOGLE:
      return <Google fontSize="20px" />;
    case SocialSignOnProvider.APPLE:
      return <Apple fontSize="20px" />;
    case SocialSignOnProvider.OKTA:
      return <Lock lockType="circle" fontSize="20px" />;
    default:
      return null;
  }
};

export const showSSOButtons = (): boolean => !isSocialBrowser() && !isWebView();

export const showSSOButtonsOnSignIn = (): boolean =>
  !isSocialBrowser() && !isWebView();

export const getPayloadForConfirmPassword = (
  signUpSuggestions?: ISignUpSuggestions
): {
  profileImage?: IImage;
  email?: string;
  userName?: string;
} => {
  const { email_address, first_name, last_name, profile_image_url } =
    signUpSuggestions ?? ISignUpSuggestions.build({});
  return {
    email: email_address,
    userName:
      first_name && last_name ? `${first_name} ${last_name}` : undefined,
    profileImage: IImage.build({
      url: profile_image_url,
      width: PROFILE_IMAGE_SIZE,
      height: PROFILE_IMAGE_SIZE,
    }),
  };
};
