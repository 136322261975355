import { request } from '@faire/web-api--source';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';
import qs from 'query-string';

export type QueryParameters = {
  setting: string | undefined;
  identifier: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/setting/public${queryString}`;
};

export const build = (
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/setting/public';

/**
 *     Read the configured value of the given public setting for the active user.
 *     If the active user is an admin, they can also optionally read the value for the given identifier instead of
 *     themselves (the active user)
 * @deprecated 
        This endpoint was originally written to support async fetch of a given setting.
        As such, it will serve stale settings values one the settings memento header is forwarded from the client.
        To update (all) serialized settings, atomically, use the /api/setting/client endpoint.
      
 * Product Area: SETTINGS
 */
function execute(queryParams?: QueryParameters): Promise<any>;
function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<any>>;
function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<any>;
function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<any | SuccessResponse<any>> {
  const { route, ...requestOptions } = build(queryParams, options);
  return request<any>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/setting/public/get';
export default execute;
