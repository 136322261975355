import { splitFiltersFromQueryParamString } from "@faire/retailer-shared/filters/splitFiltersFromQueryParams";
import { isEnumKey } from "@faire/typescript";
import { FilterSplitStrategy } from "@faire/web-api--source/indigofair/data/FilterSplitStrategy";
import { IGetSearchPageSDUIFilteredComponentsRequest } from "@faire/web-api--source/indigofair/data/IGetSearchPageSDUIFilteredComponentsRequest";
import { IPageIdentifier } from "@faire/web-api--source/indigofair/data/IPageIdentifier";
import { IQueryProductsRequest } from "@faire/web-api--source/indigofair/data/IQueryProductsRequest";
import { IRequestPaginationData } from "@faire/web-api--source/indigofair/data/IRequestPaginationData";
import { ISearchCatNavBannersRequest } from "@faire/web-api--source/indigofair/data/ISearchCatNavBannersRequest";
import { ISearchRequestReferrer } from "@faire/web-api--source/indigofair/data/ISearchRequestReferrer";
import { PageType } from "@faire/web-api--source/indigofair/data/PageType";
import { match as matchDiscoverRoute } from "@faire/web-api--source/routes/www/discover/searchTerm/match";
import { QueryParameters as SearchQueryParameters } from "@faire/web-api--source/routes/www/search";

import SearchRequestReferrerType = ISearchRequestReferrer.SearchRequestReferrerType;

const DESKTOP_TOTAL_ROWS = 12;
export const DESKTOP_PRODUCTS_PER_ROW = 5;
export const PAGE_SIZE = DESKTOP_PRODUCTS_PER_ROW * DESKTOP_TOTAL_ROWS;

export const getPageNumberGivenRecordOfQueryParams = (
  parsedSearch: Record<string, string | undefined>
): number => {
  if (parsedSearch.page) {
    return Array.isArray(parsedSearch.page)
      ? parseInt(parsedSearch.page[0], 10) - 1
      : parseInt(parsedSearch.page, 10) - 1;
  } else {
    return 0;
  }
};

// Utility function to parse the search query from a record of query params
export const getSearchQueryGivenRecordOfQueryParams = (
  parsedSearch: Record<string, string | undefined>
): string => {
  if (parsedSearch.q) {
    return (
      Array.isArray(parsedSearch.q) ? parsedSearch.q[0] : parsedSearch.q
    ).replace(/[-+_]/g, " ");
  }
  return "";
};

// Utility function to parse the filter keys from a record of query params and optionally filter sections
export const getFilterKeysGivenRecordOfQueryParams = (
  parsedSearch: Record<string, string | undefined>
): string[] => {
  return splitFiltersFromQueryParamString(parsedSearch.filters) ?? [];
};

export const getRefTypeGivenRecordOfQueryParams = (
  parsedSearch: Record<string, string | undefined>
) => {
  if (!parsedSearch.refType) {
    return SearchRequestReferrerType.NONE;
  }
  if (isEnumKey(SearchRequestReferrerType, parsedSearch.refType)) {
    return SearchRequestReferrerType[parsedSearch.refType];
  }
  return SearchRequestReferrerType.SEARCH_REQUEST_REFERRER_TYPE_UNKNOWN;
};

export const getDoQuerySpellcheckGivenRecordOfQueryParams = (
  parsedSearch: Record<string, string | undefined>
) => {
  return parsedSearch.doQuerySpellCheck === undefined;
};

export const getRefReqIdGivenRecordOfQueryParams = (
  parsedSearch: Record<string, string | undefined>
) => {
  return parsedSearch.refReqId;
};

export const getPageSizeGivenRecordOfQueryParams = (
  parsedSearch: Record<string, string | undefined>
) => {
  const pageSize: number | undefined = parsedSearch.ps
    ? parseInt(parsedSearch.ps, 10)
    : undefined;
  if (pageSize && (pageSize > PAGE_SIZE || pageSize < 0)) {
    return undefined;
  }
  return pageSize;
};

export const getFilterChangeGivenRecordOfQueryParams = (
  parsedSearch: Record<string, string | undefined>
) => {
  return parsedSearch.fc === "true";
};

export const buildSearchBannersRequest = (params: {
  parsedSearch: Record<string, string | undefined>;
}) => {
  return ISearchCatNavBannersRequest.build({
    query: getSearchQueryGivenRecordOfQueryParams(params.parsedSearch),
    filter_keys: getFilterKeysGivenRecordOfQueryParams(params.parsedSearch),
  });
};

export const buildDiscoverBannersRequest = (params: {
  parsedSearch: Record<string, string | undefined>;
}) => {
  return ISearchCatNavBannersRequest.build({
    query: getSearchQueryGivenRecordOfQueryParams(params.parsedSearch),
    filter_keys: getFilterKeysGivenRecordOfQueryParams(params.parsedSearch),
    page_type: PageType.DISCOVER_PAGE,
  });
};

// Utility function to build the API request for query-product-with-lean-tiles
export const buildSearchProductsRequest = (params: {
  filterSplitStrategy: FilterSplitStrategy;
  parsedSearch: Record<string, string | undefined>;
  shouldRequestRetrievalRankingDebugInfo: boolean;
  pageSize?: number;
  pageNumber?: number;
  isDiscoverV3Search?: boolean;
}) => {
  const refReqId = getRefReqIdGivenRecordOfQueryParams(params.parsedSearch);
  const request = IQueryProductsRequest.build({
    query: getSearchQueryGivenRecordOfQueryParams(params.parsedSearch),
    filter_keys: getFilterKeysGivenRecordOfQueryParams(params.parsedSearch),
    page_number:
      params.pageNumber ??
      getPageNumberGivenRecordOfQueryParams(params.parsedSearch),
    page_size: params.pageSize ?? PAGE_SIZE,
    return_filter_sections: true,
    container_name: params.isDiscoverV3Search
      ? "discover_grid"
      : "search_results_grid",
    referrer: ISearchRequestReferrer.build({
      referrer_type: getRefTypeGivenRecordOfQueryParams(params.parsedSearch),
    }),
    allow_query_pruning: true,
    allow_related_brands_search: true,
    allow_semantically_similar_results: true,
    do_query_spell_check: getDoQuerySpellcheckGivenRecordOfQueryParams(
      params.parsedSearch
    ),
    return_related_explore_keyword: false,
    filter_split_strategy: params.filterSplitStrategy,
    return_preorderable_products: true,
    // TODO: we can remove enable_preorderable_delivery_window_filter because settingDeliveryWindowFilterInProductSearch is always set to true
    enable_preorderable_delivery_window_filter: true,
    return_retrieval_and_ranking_debug_info:
      params.shouldRequestRetrievalRankingDebugInfo,
  });

  // This is to avoid the inconsistent unsetting of undefined fields when matching with the prefetch request body
  if (refReqId && request.referrer) {
    request.referrer.referrer_request_id = refReqId;
  }

  return request;
};

export const buildSearchFilteredComponentsRequest = (params: {
  parsedSearch: Record<string, string | undefined>;
  shouldRequestRetrievalRankingDebugInfo: boolean;
  pageSize?: number;
  pageNumber?: number;
}): IGetSearchPageSDUIFilteredComponentsRequest => {
  return IGetSearchPageSDUIFilteredComponentsRequest.build({
    page_identifier: IPageIdentifier.build({
      search_query: IPageIdentifier.ISearchQuery.build({
        query: getSearchQueryGivenRecordOfQueryParams(params.parsedSearch),
      }),
    }),
    filter_keys: getFilterKeysGivenRecordOfQueryParams(params.parsedSearch),
    pagination_data: IRequestPaginationData.build({
      page_number:
        params.pageNumber ??
        getPageNumberGivenRecordOfQueryParams(params.parsedSearch),
      page_size: params.pageSize ?? PAGE_SIZE,
    }),
    return_retrieval_and_ranking_debug_info:
      params.shouldRequestRetrievalRankingDebugInfo,
    container_name: "search_results_grid",
  });
};

export const formatDiscoverSearchTerm = (searchTerm: string): string => {
  // For the /discover/:searchTerm path, we want all spaces to be dashes
  // This is important for SEO, please don't change before speaking with Growth team
  return decodeURIComponent(searchTerm.replace(/-/g, " "));
};

export const parseSearch = (options: {
  search: string | undefined;
  discoverSearchTerm?: string;
}): Record<string, string | undefined> => {
  const query = options.discoverSearchTerm
    ? formatDiscoverSearchTerm(options.discoverSearchTerm)
    : undefined;
  const parsed = SearchQueryParameters.parse(options.search);
  if (!parsed.q) {
    parsed.q = query;
  }
  return parsed;
};

export function getDiscoverParsedSearch(
  pathname: string
): Record<string, string | undefined> {
  let search = "";
  let discoverSearchTerm: string | undefined = "";
  const startIndexOfQueryParams = pathname.indexOf("?");
  if (startIndexOfQueryParams !== -1) {
    search = pathname.substring(startIndexOfQueryParams);
    const pathParams = pathname.substring(0, startIndexOfQueryParams);
    discoverSearchTerm = matchDiscoverRoute(pathParams)?.params.searchTerm;
  } else {
    discoverSearchTerm = matchDiscoverRoute(pathname)?.params.searchTerm;
  }

  return parseSearch({
    search,
    discoverSearchTerm,
  });
}

export function getDiscoverSearchTerm(pathname: string): string | undefined {
  return getDiscoverParsedSearch(pathname).q;
}
