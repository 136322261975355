import { setIntervalIfPossible } from "@faire/retailer-visitor-shared/lib/async-functions/setIntervalIfPossible";
import {
  Provider,
  useStore,
} from "@faire/retailer-visitor-shared/stores/domain/OpeningOrderIncentivesContextStore";
import { getCurrentPath } from "@faire/web--source/common/Path";
import { Storage } from "@faire/web--source/common/Storage";
import { trackEvent } from "@faire/web--source/common/trackEvent";
import { getTime } from "date-fns/getTime";
import React, { useEffect } from "react";

const isDrivenFromEmailStorageKey = "OpeningOrderIncentivesIsDrivenFromEmail";
const INTERVAL_MS = 60 * 1_000;

const OpeningOrderIncentivesProviderBase: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [_, setStore] = useStore([]);

  useEffect(() => {
    if (
      getCurrentPath().searchParams.get("oeln") ===
      "opening-order-incentives-marketing"
    ) {
      Storage.session.setItem(isDrivenFromEmailStorageKey, "true");
      trackEvent("opening-order-incentives/marketing-email/driven-from");
    }
  }, []);

  useEffect(() => {
    const timer = () => {
      setStore({
        currentTime: getTime(new Date()),
      });
    };
    const _interval = setIntervalIfPossible(timer, INTERVAL_MS);
    return () => clearInterval(_interval);
  }, [setStore]);

  return <>{children}</>;
};

export const OpeningOrderIncentivesProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  return (
    <Provider>
      <OpeningOrderIncentivesProviderBase>
        {children}
      </OpeningOrderIncentivesProviderBase>
    </Provider>
  );
};
