import { SettingConst } from '@faire/web-api--source/types';

/**
 * Controls the NextJS router cache on clients
 */
export const NEXTJS_ROUTER_CACHE_250124 =
  'NEXTJS_ROUTER_CACHE_250124' as SettingConst<
    'NEXTJS_ROUTER_CACHE_250124',
    number,
    'RETAILER' | 'RETAILER_HEAD_TAGS' | 'VISITOR_WEB' | 'VISITOR_WEB_HEAD_TAGS'
  >;
