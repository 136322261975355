import { parseSearch } from "@faire/retailer-shared/search/searchRequest";
import { buildSearchPageLoadKey } from "@faire/retailer-visitor-shared/nextjs-rsc-util/buildSearchPageLoadKey";
import { isSearchPageRoute } from "@faire/retailer-visitor-shared/nextjs-rsc-util/isSearchPageRoute";
import { matchDiscoverPageRoute } from "@faire/retailer-visitor-shared/nextjs-rsc-util/matchDiscoverPageRoute";
import {
  shouldShowRscLoggedInSearchPage,
  shouldShowRscLoggedInSearchPageWithSDUI,
} from "@faire/retailer-visitor-shared/views/search-v2/util/shouldShowRscLoggedInSearchPage";
import { shouldShowRscLoggedOutSearchPage } from "@faire/retailer-visitor-shared/views/search-v2/util/shouldShowRscLoggedOutSearchPage";

// Only request RSC payload on filter, search term, page number, and spellcheck change to URL on search and discover pages
export const getShouldRequestRSCOnSearchPage = (options: {
  prevPathname: string;
  prevSearch: string;
  pathname: string;
  search: string;
}): boolean => {
  const { prevPathname, prevSearch, pathname, search } = options;
  const prevSearchPageLoadKey = buildSearchOrDiscoverPageLoadKey(
    prevPathname,
    prevSearch
  );
  const pageSearchLoadKey = buildSearchOrDiscoverPageLoadKey(pathname, search);
  return (
    (shouldShowRscLoggedOutSearchPage() ||
      shouldShowRscLoggedInSearchPage() ||
      shouldShowRscLoggedInSearchPageWithSDUI()) &&
    (isSearchPageRoute(pathname, search) !== null ||
      matchDiscoverPageRoute(pathname, search) !== null) &&
    pageSearchLoadKey !== prevSearchPageLoadKey
  );
};

const buildSearchOrDiscoverPageLoadKey = (pathname: string, search: string) => {
  const discoverSearchTerm = matchDiscoverPageRoute(pathname, search)?.params
    .searchTerm;
  const parsedSearch = parseSearch({ search, discoverSearchTerm });
  return buildSearchPageLoadKey(parsedSearch);
};
