import { DEFAULT_LOCALES } from "@faire/web--source/common/consts/DEFAULT_LOCALES";
import { HEADER_LOCALE_OVERRIDE } from "@faire/web--source/common/consts/HEADER_LOCALE_OVERRIDE";
import { THREE_TO_TWO_LETTER_LANGUAGE_CODE } from "@faire/web--source/common/consts/THREE_TO_TWO_LETTER_LANGUAGE_CODE";
import { RequestOptions } from "@faire/web-api--source/types";

import { DarkReadManager } from "@faire/retailer-visitor-shared/initialize/dark-read/DarkReadManager";

export const getRandomLocale = () => {
  const availableLocales = Object.keys(DEFAULT_LOCALES);
  const random = Math.floor(Math.random() * availableLocales.length);
  // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
  return THREE_TO_TWO_LETTER_LANGUAGE_CODE[availableLocales[random]!];
};

export const getOverrideLocaleHeader = (
  darkReadRequestOptions: RequestOptions
) => {
  const localeOverrideHeader = {};
  const shouldOverrideLocale =
    DarkReadManager.localeOverride().canPerformDarkReads;
  if (shouldOverrideLocale) {
    // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
    localeOverrideHeader[HEADER_LOCALE_OVERRIDE] = getRandomLocale();
  }

  return Object.assign(
    {},
    darkReadRequestOptions.headers,
    localeOverrideHeader
  );
};
