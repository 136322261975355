import { useIsOnCartPage } from "@faire/retailer-visitor-shared/hooks/cart/useIsOnCartPage";
import { useQueryParams } from "@faire/web--source/ui/hooks/useQueryParams";
import { QueryParameters as CartPageQueryParams } from "@faire/web-api--source/routes/www/cart";
import { useMemo } from "react";

export const useFilterKeys = () => {
  const isOnCartPage = useIsOnCartPage();
  const { filters } = useQueryParams(CartPageQueryParams.parse);

  const filterKeys = useMemo(() => filters?.split(","), [filters]);
  return isOnCartPage ? filterKeys : undefined;
};
