import { getBrandPageLoadKey } from "@faire/retailer-visitor-shared/nextjs-rsc-util/getBrandPageLoadKey";
import { isBrandPageRoute } from "@faire/retailer-visitor-shared/nextjs-rsc-util/isBrandPageRoute";
import { shouldShowRscLibp } from "@faire/retailer-visitor-shared/views/brand/utils/shouldShowRscLibp";
import { QueryParameters as BrandPageQueryParams } from "@faire/web-api--source/routes/www/brand/brandToken";

const extractBrandToken = (pathname: string): string => {
  const matches = pathname.match(/\/brand\/(b_[a-z0-9]+)/i);
  return matches?.[1] ?? "";
};

// Only request RSC payload on filter, search term, page number, and spellcheck change to URL on search and discover pages
export const getShouldRequestRSCOnBrandPage = (options: {
  prevSearch: string;
  pathname: string;
  search: string;
}): boolean => {
  const { prevSearch, pathname, search } = options;

  const brandToken = extractBrandToken(pathname);
  const prevBrandPageLoadKey = buildBrandPageLoadKey({
    brandToken,
    search: prevSearch,
  });
  const pageBrandPageLoadKey = buildBrandPageLoadKey({
    brandToken,
    search,
  });
  return (
    shouldShowRscLibp() &&
    isBrandPageRoute(pathname, search) !== null &&
    pageBrandPageLoadKey !== prevBrandPageLoadKey
  );
};

const buildBrandPageLoadKey = (options: {
  brandToken: string;
  search: string;
}) => {
  const { brandToken, search } = options;
  const parsedBrandPage = BrandPageQueryParams.parse(search);
  return getBrandPageLoadKey({
    brandToken: brandToken ?? "",
    query: parsedBrandPage.q ?? "",
    filters: parsedBrandPage.filters ?? "",
    pageNumber: parsedBrandPage.page ?? "",
    collections: parsedBrandPage.collections ?? "",
  });
};
