import { getFaireDirectBrandToken } from "@faire/retailer-visitor-shared/serialized-data/getFaireDirectBrandToken";
import { BrandStore } from "@faire/retailer-visitor-shared/stores/domain/BrandStore";
import { logError } from "@faire/web--source/common/logging";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import { isWindowUndefined } from "@faire/web--source/common/server-side-rendering/isWindowUndefined";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { IBrand } from "@faire/web-api--source/indigofair/data/IBrand";
import { action, computed, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";

/**
 * @deprecated All new usages should use `useFaireDirectBrand` instead. This store
 * cannot be removed yet because the following dependencies need to be removed:
 * 1. `SignUpRoot`, `SignUpState`, and `SignUpFormState`: these will be removed soon
 * in favour for the new sign-up flow.
 * 2. `AppSignUpState` will be re-written to hooks in H2 2024.
 * 3. Finally, `ElevatePortalStore.test.ts`, `initializeApp.ts` (SPA), and `AppInitializer`
 * (Next) will be removed when this store is removed.
 */
export class ElevatePortalStore {
  /**
   * @trackfunction
   */
  static get = singletonGetter(ElevatePortalStore);

  @observable
  private elevateBrandResponse?: IPromiseBasedObservable<IBrand | undefined>;

  private initializeStorePromise?: Promise<void>;

  constructor() {
    makeObservable(this);
    this.init();
  }

  // Duplicated for createStoreHook in useFaireDirectStore
  componentDidMount() {
    this.init();
  }

  private init = () => {
    if (isWindowUndefined()) {
      return;
    }

    this.initializeStorePromise = Promise.all([this.fetchElevateBrand()]).then(
      () => {}
    );
  };

  // unused-class-members-ignore-next
  waitToInitialize = async () => {
    await this.initializeStorePromise;
  };

  get elevateBrandToken(): string {
    return getFaireDirectBrandToken() ?? "";
  }

  /**
   * @deprecated Use `isFaireDirectActiveInLoggedOutWithLocation` instead
   * for a more accurate determination of whether Faire Direct is active.
   */
  get active(): boolean {
    return !!this.elevateBrandToken;
  }

  @computed
  get faireDirectAlias(): string | undefined {
    return this.elevateBrand?.token_alias;
  }

  @computed
  get elevateBrand(): IBrand | undefined {
    return this.elevateBrandResponse?.case({
      fulfilled: (response) => response,
    });
  }

  @action
  private fetchElevateBrand = async () => {
    try {
      if (this.active) {
        this.elevateBrandResponse = fromPromise(
          BrandStore.get().getBrand(this.elevateBrandToken)
        );
        await this.elevateBrandResponse;
      }
    } catch (error) {
      logError(error);
    }
  };
}
