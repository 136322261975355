import { PromoBannerType } from "@faire/retailer-visitor-shared/components/Notifications/PromoBanner/bannerOrder";
import { useMarketStore } from "@faire/retailer-visitor-shared/stores/domain/Events/FaireMarkets/MarketStore";
import { usePromoBanners } from "@faire/retailer-visitor-shared/stores/ui/usePromoBanners";
import { useEffect } from "react";

export const useSynchronizePromoBanners = () => {
  const { banners } = usePromoBanners();
  const { setHasMarketCountdownBanner } = useMarketStore([
    "setHasMarketCountdownBanner",
  ]);

  useEffect(() => {
    setHasMarketCountdownBanner(
      banners && banners[0] === PromoBannerType.MARKET_COUNTDOWN
    );
  }, [banners, setHasMarketCountdownBanner]);

  return null;
};
