import { request } from '@faire/web-api--source';
import { AdsBaitResponse } from '@faire/web-api--source/com/faire/ads_server/AdsBaitResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/ads/ad`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/ads/ad';

/**
 * Product Area: ADS
 */
function execute(): Promise<AdsBaitResponse>;
function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<AdsBaitResponse>>;
function execute(options?: EndpointOptions): Promise<AdsBaitResponse>;
function execute(
  options?: EndpointOptions
): Promise<AdsBaitResponse | SuccessResponse<AdsBaitResponse>> {
  const { route, ...requestOptions } = build(options);
  return request<AdsBaitResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/ads/ad/get';
export default execute;
