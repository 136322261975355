import { request } from '@faire/web-api--source';
import { IRetailerPosGetSummaryResponse } from '@faire/web-api--source/indigofair/retailers/pos/IRetailerPosGetSummaryResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';
import qs from 'query-string';

export type QueryParameters = {
  brand_orders: Array<string> | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/retailers-pos-api/summary${queryString}`;
};

export const build = (
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/retailers-pos-api/summary';

/**
 * Product Area: RETAILER_POS_INTEGRATIONS
 */
function execute(
  queryParams?: QueryParameters
): Promise<IRetailerPosGetSummaryResponse>;
function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IRetailerPosGetSummaryResponse>>;
function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<IRetailerPosGetSummaryResponse>;
function execute(
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<
  | IRetailerPosGetSummaryResponse
  | SuccessResponse<IRetailerPosGetSummaryResponse>
> {
  const { route, ...requestOptions } = build(queryParams, options);
  return request<IRetailerPosGetSummaryResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/retailers-pos-api/summary/get';
export default execute;
