export const getBrandPageLoadKey = (options: {
  brandToken: string;
  query: string;
  filters: string;
  pageNumber: string;
  collections?: string;
  reorder?: string;
  sortBy?: string;
}) => {
  const {
    brandToken,
    query,
    filters,
    pageNumber,
    collections,
    reorder,
    sortBy,
  } = options;
  return `${brandToken}-${query}-${filters}-${pageNumber}-${collections}-${reorder}-${sortBy}`;
};
