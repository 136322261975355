import { trackClientEvent } from '@faire/web-api--source/trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey = 'infra-web_memory_usage-action_unknown';

/**
 * This event measures memory usage statistics for web clients at regular intervals.
 */
export function trackInfraWebMemoryUsage(
  Browser_Name: string,
  Browser_Version2: string,
  OS_Name3: string,
  OS_Version4: string,
  Device_Type5: string,
  Memory_Limit6: string,
  Memory_Total7: string,
  Memory_Used8: string,
  Session_Time9: string,
  App10: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: {
      Browser_Name,
      Browser_Version2,
      OS_Name3,
      OS_Version4,
      Device_Type5,
      Memory_Limit6,
      Memory_Total7,
      Memory_Used8,
      Session_Time9,
      App10,
    },
  });
}
