import { IBrandCartV2 } from '@faire/web-api--source/faire/carts/IBrandCartV2';
import { IBrandPromo } from '@faire/web-api--source/indigofair/data/IBrandPromo';
import { IDisclaimer } from '@faire/web-api--source/indigofair/data/IDisclaimer';
import { IFaireMoney } from '@faire/web-api--source/indigofair/data/IFaireMoney';
import { IInformationMessage } from '@faire/web-api--source/indigofair/data/IInformationMessage';
import { IOrderSummaryLineItem } from '@faire/web-api--source/indigofair/data/IOrderSummaryLineItem';
import { IProductMessage } from '@faire/web-api--source/indigofair/retailer/IProductMessage';

export namespace ICartSummaryResponseV2 {
  export namespace ICartSummary {
    /**
     * Return an instance of ICartSummary with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (partial?: Partial<ICartSummary>): ICartSummary =>
      ({
        brand_line_items: [],
        general_line_items: [],
        ...(partial ?? {}),
      }) as ICartSummary;
  }

  export interface ICartSummary {
    brand_line_items: Array<IOrderSummaryLineItem>;
    general_line_items: Array<IOrderSummaryLineItem>;
    total_line_item: IOrderSummaryLineItem | undefined;
    sub_total: IFaireMoney | undefined;
    total: IFaireMoney | undefined;
    chargeable_total: IFaireMoney | undefined;
    disclaimer: string | undefined;
    has_free_shipping_for_all_brands: boolean | undefined;
    /**
     * @deprecated
     */
    has_excluded_discounts: boolean | undefined;
    /**
     * @deprecated
     */
    payment_line_item: IOrderSummaryLineItem | undefined;
    checkout_banner_message: IProductMessage | undefined;
  }

  export namespace IBrandCartSummary {
    /**
     * Return an instance of IBrandCartSummary with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IBrandCartSummary>
    ): IBrandCartSummary =>
      ({
        brand_promos: [],
        order_summary_line_items: [],
        ...(partial ?? {}),
      }) as IBrandCartSummary;
  }

  export interface IBrandCartSummary {
    /**
     * @protobufOption (.faire.common.tokenType) = BRAND
     */
    brand_token: string | undefined;
    brand_name: string | undefined;
    /**
     * @deprecated
     */
    estimated_shipping_total: IFaireMoney | undefined;
    discount_total: IFaireMoney | undefined;
    /**
     * In the process of refactoring to allow multiple brand promos. Use `brandPromos` field instead if
     * USE_BRAND_PROMOS_LIST_220310 is true
     * @deprecated
     */
    brand_promo: IBrandPromo | undefined;
    brand_promos: Array<IBrandPromo>;
    is_eligible_for_insider: boolean | undefined;
    total: IFaireMoney | undefined;
    order_summary_line_items: Array<IOrderSummaryLineItem>;
    disclaimer: string | undefined;
    brand_cart_state: keyof typeof IBrandCartV2.State | undefined;
    information_message: IInformationMessage | undefined;
  }

  /**
   * Return an instance of ICartSummaryResponseV2 with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<ICartSummaryResponseV2>
  ): ICartSummaryResponseV2 =>
    ({
      brand_cart_summaries: {},
      ...(partial ?? {}),
    }) as ICartSummaryResponseV2;
}

export interface ICartSummaryResponseV2 {
  /**
   * @protobufOption (.faire.common.tokenType) = CART
   */
  cart_token: string | undefined;
  cart_summary: ICartSummaryResponseV2.ICartSummary | undefined;
  brand_cart_summaries: Partial<
    Record<string, ICartSummaryResponseV2.IBrandCartSummary>
  >;
  disclaimer: IDisclaimer | undefined;
  information_message: IInformationMessage | undefined;
}
