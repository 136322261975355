import { LazyFirstOrderIncentiveFab } from "@faire/retailer-visitor-shared/components/FirstOrderIncentiveFab/LazyFirstOrderIncentiveFab";
import { useDismissFOIModalMilestones } from "@faire/retailer-visitor-shared/hooks/firstOrderIncentive/useDismissFOIModalMilestones";
import { useFirstOrderIncentiveStore } from "@faire/retailer-visitor-shared/hooks/firstOrderIncentive/useFirstOrderIncentiveStore";
import { useSettingImprovedFoiExperience } from "@faire/retailer-visitor-shared/settings/useSettingImprovedFoiExperience";
import { RetailerRoute } from "@faire/web--source/common/routes/RetailerRouteMatcher";
import { RouteMatcher } from "@faire/web--source/common/routes/RouteMatcher";
import { useViewport } from "@faire/web--source/ui/hooks/useViewport";
import { route as boardsRoute } from "@faire/web-api--source/routes/www/boards";
import { route as brandsRoute } from "@faire/web-api--source/routes/www/brands";
import { route as cartRoute } from "@faire/web-api--source/routes/www/cart";
import { route as checkoutRoute } from "@faire/web-api--source/routes/www/checkout";
import { route as marketsRoute } from "@faire/web-api--source/routes/www/markets";
import { route as messagesRoute } from "@faire/web-api--source/routes/www/messages";
import { route as netTermsRoute } from "@faire/web-api--source/routes/www/net-terms";
import { route as openWithFaireRoute } from "@faire/web-api--source/routes/www/open-with-faire";
import { route as pointOfSaleRoute } from "@faire/web-api--source/routes/www/point-of-sale";
import { route as retailerSettingsAvailableCreditRoute } from "@faire/web-api--source/routes/www/retailer/settings/available-credit";
import { route as retailerSettingsDeactivateAccountRoute } from "@faire/web-api--source/routes/www/retailer/settings/deactivate-account";
import { route as retailerSettingsEmailPreferencesRoute } from "@faire/web-api--source/routes/www/retailer/settings/email-preferences";
import { route as retailerSettingsInsiderMembershipRoute } from "@faire/web-api--source/routes/www/retailer/settings/insider-membership";
import { route as retailerSettingsMyAddressesRoute } from "@faire/web-api--source/routes/www/retailer/settings/my-addresses";
import { route as retailerSettingsMyTeamRoute } from "@faire/web-api--source/routes/www/retailer/settings/my-team";
import { route as retailerSettingsPaymentMethodsRoute } from "@faire/web-api--source/routes/www/retailer/settings/payment-methods";
import { route as retailerSettingsPosManagerRoute } from "@faire/web-api--source/routes/www/retailer/settings/pos-manager";
import { route as retailerSettingsProfileRoute } from "@faire/web-api--source/routes/www/retailer/settings/profile";
import { route as retailerSettingsSecurityRoute } from "@faire/web-api--source/routes/www/retailer/settings/security";
import { route as retailerSettingsShippingPreferencesRoute } from "@faire/web-api--source/routes/www/retailer/settings/shipping-preferences";
import { route as retailerSettingsStoresRoute } from "@faire/web-api--source/routes/www/retailer/settings/stores";
import { route as smallBusinessGrantRoute } from "@faire/web-api--source/routes/www/small-business-grant";
import { route as smallBusinessGrantApplyRoute } from "@faire/web-api--source/routes/www/small-business-grant/apply";
import { route as smallBusinessGrantTosRoute } from "@faire/web-api--source/routes/www/small-business-grant/tos";
import { route as userAccountRoute } from "@faire/web-api--source/routes/www/user/account";
import { route as userBrandReferralsRoute } from "@faire/web-api--source/routes/www/user/brand-referrals";
import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";

const FOI_FAB_BLOCKED_PATHS: RetailerRoute[] = [
  cartRoute,
  checkoutRoute,
  userAccountRoute,
  messagesRoute,
  boardsRoute,
  pointOfSaleRoute,
  netTermsRoute,
  openWithFaireRoute,
  userBrandReferralsRoute,
  marketsRoute,
  brandsRoute,
  smallBusinessGrantRoute,
  smallBusinessGrantApplyRoute,
  smallBusinessGrantTosRoute,
  retailerSettingsStoresRoute,
  retailerSettingsMyTeamRoute,
  retailerSettingsPaymentMethodsRoute,
  retailerSettingsShippingPreferencesRoute,
  retailerSettingsProfileRoute,
  retailerSettingsEmailPreferencesRoute,
  retailerSettingsSecurityRoute,
  retailerSettingsPosManagerRoute,
  retailerSettingsInsiderMembershipRoute,
  retailerSettingsDeactivateAccountRoute,
  retailerSettingsAvailableCreditRoute,
  retailerSettingsMyAddressesRoute,
];

export const FirstOrderIncentiveFabWrapper: React.FC = () => {
  const { isMobile } = useViewport();
  const { pathname } = useLocation();
  const isImprovedFoiExperience = useSettingImprovedFoiExperience();
  const {
    isFirstOrderForFaireDirectIncentive,
    isRetailerFoiEligible,
    isLastChanceIncentive,
  } = useFirstOrderIncentiveStore();
  const { foiModalDismissCount, lastChanceFoiModalDismissCount } =
    useDismissFOIModalMilestones();

  const excludedRouteMatcher: RouteMatcher<RetailerRoute> = new RouteMatcher(
    FOI_FAB_BLOCKED_PATHS
  );
  const isLocationBlocked = !!excludedRouteMatcher.match(pathname);
  const hasDimissedInitialModal = isLastChanceIncentive
    ? lastChanceFoiModalDismissCount > 0
    : foiModalDismissCount > 0;

  // precondition: REMOVE_MESSENGER_FAB_241112 is true, since FOI FAB and Messenger FAB take the same real estate
  const canShow =
    !isMobile &&
    !isFirstOrderForFaireDirectIncentive &&
    isRetailerFoiEligible &&
    !isLocationBlocked &&
    hasDimissedInitialModal &&
    isImprovedFoiExperience;

  if (!canShow) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyFirstOrderIncentiveFab />
    </Suspense>
  );
};
