import {
  RequestInterceptor,
  RequestOptions,
} from "@faire/web-api--source/types";

interface HeaderOptions {
  header: string;
  value: string | (() => string | undefined);
  override?: boolean;
}

/**
 * Factory for creating an interceptor that amends the outgoing request by
 * adding an additional header
 */
export const headerInterceptorFactory = (
  ...header: HeaderOptions[]
): RequestInterceptor => {
  return (config: RequestOptions) => {
    const headers = config.headers || (config.headers = {});
    for (const h of header) {
      if (headers[h.header] && !h.override) {
        continue;
      }

      if (typeof h.value === "string") {
        headers[h.header] = h.value;
      } else {
        const value = h.value();
        if (value) {
          headers[h.header] = value;
        }
      }
    }
    return config;
  };
};
