// Duplicate of core/web/src/common/typescript. Make sure to keep in sync.
// NOTE(luke): Don't import anything other than types in this file.
import type { Concat, Repeat } from "typescript-tuple";

// If your util depends on an external library, it's not a simple typescript util,
// which is what this file is intended for.

export interface IStringTMap<T> {
  [key: string]: T;
}

/**
 * Infers the type of a set element
 */
export type SetElement<T> = T extends Set<infer K> ? K : never;

/**
 * Type extraction that produces an partial interface of `T` that
 * only includes properties that have value type of `P`.
 */
export type PropsOfType<T, P> = Pick<
  T,
  {
    [K in keyof T]-?: Exclude<T[K], undefined> extends P ? K : never;
  }[keyof T]
>;

/**
 * Type extraction that produces the equivalent of an IStringTMap<string>,
 * except that the properties are a named subset of `T`s properties.
 *
 * `F` is an optional type union for fields to filter down to.
 */
export type ErrorsFor<T, F extends keyof T = keyof T> = Pick<
  {
    [k in keyof T]?: string;
  },
  F
>;

export const isTupleOfLength = <T, N extends number>(
  array: T[],
  length: N
): array is Repeat<T, N> => array.length === length;

export const isTupleOfLengthGreaterThanOrEqual = <T, N extends number>(
  array: T[],
  length: N
): array is Concat<Repeat<T, N>, T[]> => array.length >= length;

export type NotNull<T> = Exclude<T, null>;
export const isNotNull = <T>(input: null | NotNull<T>): input is NotNull<T> =>
  input !== null;

export type NotUndefined<T> = Exclude<T, undefined>;
export const isNotUndefined = <T>(
  input: undefined | NotUndefined<T>
): input is NotUndefined<T> => input !== undefined;

/**
 * Checks if value is nullish (null or undefined). Equivalent to a `== null` check.
 *
 * @param {unknown} value the value to check
 * @returns {boolean} true if value is nullish (null or undefined), otherwise false
 */
export const isNullish = (value: unknown): value is undefined | null =>
  value === null || value === undefined;

export const keyBy = <T extends number | string | symbol, S>(
  array: T[],
  iteratee: (elem: T, index?: number) => S
): { [K in T]: S } =>
  Object.assign(
    {},
    ...array.map((elem, index) => ({ [elem]: iteratee(elem, index) }))
  );

export type RecursiveRequired<T> = {
  [P in keyof T]-?: RecursiveRequired<T[P]>;
};

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * Strongly-typed Object.keys for a precise instantiated type (e.g. a IFoo.build from faire/web-api).
 *
 * This should never be used on anything that will be extended, as the type constraint won't be true,
 * since the keys would be a superset of the given type.
 */
export const protoKeys = <T extends object>(proto: T): Array<keyof T> => {
  return Object.keys(proto) as Array<keyof T>;
};

/**
 * Strongly-typed Object.values for a precise instantiated type (e.g. a IFoo.build from faire/web-api).
 *
 * This should never be used on anything that will be extended, as the type constraint won't be true,
 * since the keys/entries would be a superset of the given type.
 */
export const protoValues = <T extends object>(proto: T): Array<T[keyof T]> => {
  return Object.values(proto) as Array<T[keyof T]>;
};

/**
 * Strongly-typed Object.entries for a precise instantiated type (e.g. a IFoo.build from faire/web-api).
 *
 * This should never be used on anything that will be extended, as the type constraint won't be true,
 * since the keys/entries would be a superset of the given type.
 */
export const protoEntries = <T extends object>(
  proto: T
): Array<KeyValueTuples<T>[keyof T]> => {
  return Object.entries(proto) as Array<KeyValueTuples<T>[keyof T]>;
};
type KeyValueTuples<T> = { [K in keyof T]: [K, T[K]] };

export const enumValues = <T extends object>(enumObject: T): T[keyof T][] =>
  Object.values(enumObject);

export const enumKeys = <T extends object>(enumObject: T): (keyof T)[] =>
  Object.keys(enumObject) as (keyof T)[];

export const isInEnum = <T>(value: unknown, values: T[]): value is T =>
  values.includes(value as T);

export function isEnumValue<T extends object>(
  enumObject: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): value is T[keyof T];
export function isEnumValue<T extends string>(
  enumObject: Array<T>,
  value: string
): value is T;
export function isEnumValue<T>(
  enumObject: T[] | object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): value is T {
  return Array.isArray(enumObject)
    ? enumObject.includes(value)
    : enumValues(enumObject as unknown as object & T).includes(value);
}

export const isEnumKey = <T extends object>(
  enumObject: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): value is keyof T => enumKeys(enumObject).includes(value);

/**
 * Gets the enum value if the given key is in the enum, or return undefined.
 */
export const enumValue = <T extends object>(
  enumObject: T,
  key: string | undefined
): T[keyof T] | undefined => {
  if (isEnumKey(enumObject, key)) {
    return enumObject[key];
  }
  return undefined;
};

/**
 * Return the given value of `indexable` at the given key with the type
 * `unknown`.
 *
 * When the key isn't statically guaranteed to be part
 * of the object's schema, the return value could be anything, as object
 * values can have additional key/value pairs and still satisfy the type
 * signature of a smaller schema. For example, `{a: 1, b: 'hi'}` is a
 * valid instance of `{a: number}`, so indexing it with an arbitrary index
 * could return a string, even though string is never even mentioned in
 * the type.
 */
export const getUnknownProperty = (
  indexable: object,
  key: number | string | symbol
): unknown => {
  const asRecord: Partial<Record<number | string | symbol, unknown>> =
    indexable;
  return asRecord[key];
};

/**
 * Return the given value of `indexable` at the given key, if it is a string,
 * and otherwise return `undefined`.
 */
export const getStringProperty = (
  indexable: object,
  key: number | string | symbol
): string | undefined => {
  const valueUnknown = getUnknownProperty(indexable, key);
  return typeof valueUnknown === "string" ? valueUnknown : undefined;
};

export const hasErrorMessage = (
  error: { message: unknown } | object
): error is { message: string } =>
  "message" in error && typeof error.message === "string";

const hasErrorName = (
  error: { name: unknown } | object
): error is { name: string } =>
  "name" in error && typeof error.name === "string";

const maybeHasErrorStack = (
  error: { stack?: unknown } | object
): error is { stack?: string } =>
  !("stack" in error) || typeof error.stack === "string";

const isErrorLike = (error: unknown): error is Error =>
  typeof error === "object" &&
  !isNullish(error) &&
  hasErrorMessage(error) &&
  hasErrorName(error) &&
  maybeHasErrorStack(error);

export const isError = (error: unknown): error is Error =>
  error instanceof Error || isErrorLike(error);

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;

type PromiseOrUndefined = Promise<unknown> | undefined;

/**
 * Returns true if `maybePromise` implements the `Promise` interface (.then and .catch)
 */
export const isPromise = (
  maybePromise: unknown
): maybePromise is Promise<unknown> =>
  typeof (maybePromise as PromiseOrUndefined)?.then === "function" &&
  typeof (maybePromise as PromiseOrUndefined)?.catch === "function";

/**
 * Return object with the first N elements
 * Note: There is no guarantee on iteration order, and may produce different results across browsers
 */
export const firstNObjectValues = <T>(
  numElements: number,
  object: {
    [index: string]: T;
  }
): {
  [index: string]: T;
} => {
  const entries = Object.entries(object).slice(0, numElements);
  return Object.fromEntries(entries);
};

export type RemoveIndex<T> = {
  [K in keyof T as string extends K
    ? never
    : number extends K
      ? never
      : symbol extends K
        ? never
        : K]: T[K];
};

/**
 * Converts all optional properties of type `T` of an object to required with type `T | undefined`
 *
 * @example
 * ```tsx
 * interface Foo {
 *   a?: string;
 *   b: string;
 * }
 * type Bar = ExplicitOptional<Foo>;
 * // invalid: "Property 'a' is missing in type '{ b: string; }' but required in type 'ExplicitOptional<Foo>'."
 * const bar1: Bar = { b: "b" };
 * // invalid: "Type 'undefined' is not assignable to type 'string'"
 * const bar2: Bar = { a: "a", b: undefined };
 * // valid!
 * const bar3: Bar = { a: undefined, b: "b" };
 * const bar4: Bar = { a: "a", b: "b" };
 * ```
 */
export type ExplicitOptional<T> = {
  [P in keyof Required<T>]: T[P] extends undefined ? T[P] | undefined : T[P];
};

export type WithUndefined<T extends readonly unknown[]> = readonly [
  ...{
    [K in keyof T]: T[K] | undefined;
  },
];

export type StrictExclude<T, U> = T extends U ? (U extends T ? never : T) : T;
