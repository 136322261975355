import {
  QueryClient,
  defaultShouldDehydrateQuery,
} from "@tanstack/react-query";

import { shouldRetryEndpoint } from "./shouldRetryEndpoint";

export const buildQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: shouldRetryEndpoint,
      },
      dehydrate: {
        shouldDehydrateQuery: (query) =>
          defaultShouldDehydrateQuery(query) ||
          query.state.status === "pending",
      },
    },
  });
};
