"use client";

import { useSearchParamsString } from "@faire/next-utils/client/useSearchParamsString";
import { IUrl, sanitizeUrl } from "@faire/next-utils/isomorphic/sanitizeUrl";
import { useCustomRouterCache } from "@faire/retailer-visitor-shared/app/_lib/routing/CustomRouterCache/useCustomRouterCache";
import { useRouter } from "@faire/retailer-visitor-shared/app/_lib/routing/useRouter";
import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import { RoutingContext } from "@faire/web--source/ui/routing/RoutingContext";
import {
  getLocalePrefix,
  removeLocaleFromUrl,
} from "@faire/web--source/ui/routing/util";
import { LocationDescriptorObject, MemoryHistory, parsePath } from "history";
import { PrefetchKind } from "next/dist/client/components/router-reducer/router-reducer-types";
import { usePathname } from "next/navigation";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useTransition } from "react";
import { Router } from "react-router-dom";

import { isPathnameEqual } from "./__internal__/isPathnameEqual";
import { getShouldRequestRSC } from "./getShouldRequestRSC";
import { useRouterTransitioning } from "./useRouterTransitioning";

// React router uses function overloading instead of union types,
// so we need to define our own type here
type ReactRouterOperation = (
  path: string | LocationDescriptorObject<unknown>,
  state: unknown
) => void;

export const ReactRouterCompat = ({
  memoryHistory,
  children,
}: {
  memoryHistory: MemoryHistory;
  children: React.ReactNode;
}) => {
  const [isPending, startTransition] = useTransition();
  const setIsTransitioning = useRouterTransitioning((state) => state.setIsTransitioning);
  useEffect(() => {
    setIsTransitioning(isPending);
  }, [isPending, setIsTransitioning]);
  const appRouter = useRouter();
  const { getModifiedUrl } = useCustomRouterCache();
  const routeContext = useContext(RoutingContext);

  const pathname = usePathname();
  const searchParamsString = useSearchParamsString();
  const originalReactRouterMethods = useRef({
    push: memoryHistory.push as ReactRouterOperation,
    replace: memoryHistory.replace as ReactRouterOperation,
    goBack: memoryHistory.goBack,
  });

  /**
   * When a direct load occurs at "/category," the middleware will correct it to
   * "/{actual locale}/category" before requesting the RSC payload. However, the
   * browser history will not include the locale.
   *
   * Upon a route change, formatLocalePrefixInUrl will add the locale to the URL,
   * resulting in "/{actual locale}/category." This URL is then forwarded as is
   * to retrieve the RSC payload.
   *
   * This approach ensures that the browser pathname remains consistent with the
   * RSC payload request, which is a requirement for RSC.
   *
   * To avoid adding redundant "en-us" to the URL, especially since it is the
   * majority case, we will intentionally remove "/en-us" from the URL even if
   * it is specified. This removal is based on the convention that an empty string
   * represents "en-us."
   */
  const formatLocalePrefixInUrl = useCallback(
    (url: IUrl) => {
      const pathnameWithLocale = `${getLocalePrefix(
        routeContext?.locale,
        routeContext?.localeCountryUrlPrefix,
        routeContext?.allowedRobotLocales
      )}${removeLocaleFromUrl(sanitizeUrl({ pathname: url.pathname }))}`;
      return sanitizeUrl({
        ...url,
        pathname: pathnameWithLocale,
      });
    },
    [
      routeContext?.allowedRobotLocales,
      routeContext?.locale,
      routeContext?.localeCountryUrlPrefix,
    ]
  );

  /**
   * We use a combination of React Router's memoryHistory.location.pathname and
   * Next.js' usePathname to determine resulting logic here. This is because while
   * usePathname is async (and therefore possibly out of date with React Router),
   * it IS in sync with Next.js' internal routing state (whereas
   * memoryHistory.location.pathname isn't). If we were to use the more "up to date"
   * memoryHistory.location.pathname, we would introduce the possibility of not performing
   * an RSC request when we actually want to. In essence, usePathname should be used when
   * determining actions involving Next.js' router, whereas memoryHistory.location.pathname
   * should be used when you want the current state of the react app (i.e. useHistory).
   */
  const determineSyncActions = useCallback(
    (path: string | LocationDescriptorObject<unknown>, state?: unknown) => {
      let location: LocationDescriptorObject<unknown> | undefined;
      let fetchFromServer = false;
      if (typeof path === "string") {
        location = parsePath(path);
        if (
          state &&
          typeof state === "object" &&
          "fetchFromServer" in state &&
          typeof state.fetchFromServer === "boolean"
        ) {
          fetchFromServer = state.fetchFromServer;
          delete state.fetchFromServer;
        }
      } else {
        /**
         * React Router allows location object to miss pathname, in which case
         * the current pathname is used. Example:
         *
         * history.replace({ search: "" });
         */
        location = {
          pathname: memoryHistory.location.pathname,
          ...path,
        };

        if (
          location.state &&
          typeof location.state === "object" &&
          "fetchFromServer" in location.state &&
          typeof location.state.fetchFromServer === "boolean"
        ) {
          fetchFromServer = location.state.fetchFromServer;
          delete location.state.fetchFromServer;
        }
      }

      const urlWithLocale = formatLocalePrefixInUrl(location);
      return {
        shouldRequestRSC:
          fetchFromServer ||
          getShouldRequestRSC(
            { pathname, search: searchParamsString },
            {
              pathname: location.pathname ?? "",
              search: location.search ?? "",
            }
          ),
        isSamePage: isPathnameEqual(location.pathname ?? "", pathname),
        urlWithLocale,
      };
    },
    [
      formatLocalePrefixInUrl,
      memoryHistory.location.pathname,
      pathname,
      searchParamsString,
    ]
  );

  // Override the memoryHistory methods to ensure that the browser history and app router stays in sync.
  useLayoutEffect(() => {
    const history = getWindow()?.history;
    if (!history) {
      return;
    }

    // Handle useHistory().push
    // eslint-disable-next-line react-compiler/react-compiler
    memoryHistory.push = (
      path: string | LocationDescriptorObject<unknown>,
      state?: unknown
    ) => {
      const { shouldRequestRSC, isSamePage, urlWithLocale } =
        determineSyncActions(path, state);

      let modifiedUrlWithLocale = urlWithLocale;
      if (shouldRequestRSC) {
        modifiedUrlWithLocale = getModifiedUrl(urlWithLocale);
      }

      // React Router action
      originalReactRouterMethods.current.push.call(
        memoryHistory,
        modifiedUrlWithLocale,
        state
      );

      const location = getLocationOrThrow();
      const previousPathname = location.pathname;

      // NextJS Shallow Navigation
      history.pushState.call(history, null, "", modifiedUrlWithLocale);

      // NextJS RSC navigation
      if (shouldRequestRSC) {
        /*
        Workaround for bug https://github.com/vercel/next.js/issues/75496
        We need to prefetch the page before pushing it to the router to make sure
        the router cache is working with pages with query params.
        Will remove once the bug is fixed.  
        When removing this, make sure to remove the workaround in useRouter.tsx
        */
        appRouter.prefetch(modifiedUrlWithLocale, {
          kind: PrefetchKind.FULL,
          skipCacheUpdate: true,
        });
        setTimeout(() => {
          startTransition(() => {
            appRouter.push(modifiedUrlWithLocale, {
              scroll: !isSamePage,
              previousPathname: previousPathname,
            });
          });
        }, 0);
      }
    };

    // Handle useHistory().replace
    memoryHistory.replace = (
      path: string | LocationDescriptorObject<unknown>,
      state?: unknown
    ) => {
      const { shouldRequestRSC, isSamePage, urlWithLocale } =
        determineSyncActions(path, state);

      let modifiedUrlWithLocale = urlWithLocale;
      if (shouldRequestRSC) {
        modifiedUrlWithLocale = getModifiedUrl(urlWithLocale);
      }

      // React Router action
      originalReactRouterMethods.current.replace.call(
        memoryHistory,
        modifiedUrlWithLocale,
        state
      );

      const location = getLocationOrThrow();
      const previousPathname = location.pathname;

      // NextJS Shallow Navigation
      history.replaceState.call(history, null, "", modifiedUrlWithLocale);

      // NextJS RSC navigation
      if (shouldRequestRSC) {
        /*
        Workaround for bug https://github.com/vercel/next.js/issues/75496
        We need to prefetch the page before pushing it to the router to make sure
        the router cache is working with pages with query params.
        Will remove once the bug is fixed.
        */
        appRouter.prefetch(modifiedUrlWithLocale, { kind: PrefetchKind.FULL });
        setTimeout(() => {
          startTransition(() => {
            appRouter.replace(modifiedUrlWithLocale, {
              scroll: !isSamePage,
              previousPathname: previousPathname,
            });
          });
        }, 0);
      }
    };
    memoryHistory.goBack = () => {
      originalReactRouterMethods.current.goBack.call(memoryHistory);
      startTransition(() => {
        appRouter.back();
      });
    };
    const originalRouterMethods = originalReactRouterMethods.current;
    return () => {
      memoryHistory.push =
        originalRouterMethods.push as typeof memoryHistory.push;
      memoryHistory.replace =
        originalRouterMethods.replace as typeof memoryHistory.replace;
      memoryHistory.goBack = originalRouterMethods.goBack;
    };
  }, [appRouter, determineSyncActions, memoryHistory, getModifiedUrl]);

  /**
   * memoryHistory does not change when the user clicks the back button
   * in the browser, but nextjs does. This effect ensures that the memoryHistory stays in sync.
   */
  useLayoutEffect(() => {
    if (
      !isPathnameEqual(memoryHistory.location.pathname, pathname) ||
      !isSearchEqual(memoryHistory.location.search, searchParamsString)
    ) {
      originalReactRouterMethods.current.replace.call(
        memoryHistory,
        sanitizeUrl({
          pathname,
          search: searchParamsString,
        }),
        memoryHistory.location.state
      );
    }
  }, [memoryHistory, pathname, searchParamsString]);

  return <Router history={memoryHistory}>{children}</Router>;
};

const isSearchEqual = (a: string, b: string) => {
  const processSearchParams = (rawSearchParams: string) => {
    let searchParams = rawSearchParams;
    if (searchParams.startsWith("?")) {
      // comparison agnostic of leading question mark
      searchParams = searchParams.slice(1);
    }
    return decodeURI(searchParams);
  };

  return processSearchParams(a) === processSearchParams(b);
};
