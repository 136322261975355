import { IPageIdentifier } from '@faire/web-api--source/indigofair/data/IPageIdentifier';
import { IRequestPaginationData } from '@faire/web-api--source/indigofair/data/IRequestPaginationData';

export namespace IGetSearchPageSDUIFilteredComponentsRequest {
  /**
   * Return an instance of IGetSearchPageSDUIFilteredComponentsRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IGetSearchPageSDUIFilteredComponentsRequest>
  ): IGetSearchPageSDUIFilteredComponentsRequest =>
    ({
      filter_keys: [],
      ...(partial ?? {}),
    }) as IGetSearchPageSDUIFilteredComponentsRequest;
}

export interface IGetSearchPageSDUIFilteredComponentsRequest {
  page_identifier: IPageIdentifier | undefined;
  filter_keys: Array<string>;
  pagination_data: IRequestPaginationData | undefined;
  return_retrieval_and_ranking_debug_info: boolean | undefined;
  container_name: string | undefined;
}
