import { PERF_DARK_READ_TESTING_ENABLED } from "@faire/web-api--source/indigofair/settings/PERF_DARK_READ_TESTING_ENABLED";
import { RequestInterceptor } from "@faire/web-api--source/types";

import { DarkReadManager } from "@faire/retailer-visitor-shared/initialize/dark-read/DarkReadManager";
import {
  HEADER_DARK_READ,
  HEADER_DARK_READ_ORIGINAL_REQUEST,
} from "@faire/retailer-visitor-shared/initialize/dark-read/HEADER_DARK_READ";

/**
 * Determines whether the outgoing request should be replicated for dark reads.
 * If so, attaches the header identifying this request as the original, which is
 * picked up in the response interceptor afterwards, to replicate the requests.
 */
export const darkReadsRequestInterceptor: RequestInterceptor = (request) => {
  // Already a dark read
  if (request.headers?.[HEADER_DARK_READ]) {
    return request;
  }

  // Explicitly requested.
  if (request.darkRead) {
    request.headers = {
      ...request.headers,
      [HEADER_DARK_READ]: PERF_DARK_READ_TESTING_ENABLED,
    };
    return request;
  }

  // Implicit replication, based on setting config
  const manager = DarkReadManager.get();

  const shouldReplicate = manager.shouldMultiplyDarkReads(request);
  if (!shouldReplicate) {
    // no darks-reads, should perform request normally
    return request;
  }

  request.headers = {
    ...request.headers,
    // this header is only used to identify the original dark-read request
    // there are no side-effects on the request itself
    [HEADER_DARK_READ_ORIGINAL_REQUEST]: PERF_DARK_READ_TESTING_ENABLED,
  };
  return request;
};
