import { SettingConst } from '@faire/web-api--source/types';

/**
 * Controls whether or not we use server components to render the top of page content
 */
export const NEXTJS_RSC_TOP_OF_PAGE_CONTENT_250227 =
  'NEXTJS_RSC_TOP_OF_PAGE_CONTENT_250227' as SettingConst<
    'NEXTJS_RSC_TOP_OF_PAGE_CONTENT_250227',
    boolean,
    'RETAILER' | 'RETAILER_HEAD_TAGS' | 'VISITOR_WEB' | 'VISITOR_WEB_HEAD_TAGS'
  >;
