import { SettingConst } from '@faire/web-api--source/types';

/**
 * ID for the Facebook marketing retailer pixel
 */
export const FACEBOOK_RETAILER_PIXEL_ID =
  'FACEBOOK_RETAILER_PIXEL_ID' as SettingConst<
    'FACEBOOK_RETAILER_PIXEL_ID',
    string,
    | 'RETAILER_HEAD_TAGS'
    | 'RETAILER'
    | 'HELP_CENTER'
    | 'VISITOR_WEB'
    | 'VISITOR_WEB_HEAD_TAGS'
  >;
