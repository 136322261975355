import { SettingConst } from '@faire/web-api--source/types';

/**
 *   This setting will be serialized to the frontend to decide if a session should record
 *   detailed client side traces for the session.
 *   This is being launched as an experiment to measure the impact on performance.
 */
export const ENABLE_DETAILED_SESSION_TRACING_240424 =
  'ENABLE_DETAILED_SESSION_TRACING_240424' as SettingConst<
    'ENABLE_DETAILED_SESSION_TRACING_240424',
    boolean,
    'RETAILER' | 'MAKER' | 'VISITOR_WEB'
  >;
