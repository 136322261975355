import { SettingConst } from '@faire/web-api--source/types';

/**
 * Test displaying the auto modal on the first and second page users visit:
 * https://www.notion.so/faire/Low-lift-testing-Batch-4-91865b68cf0244c4a00ca000d196a71e?pvs=4
 */
export const SECOND_PAGE_AUTO_MODAL_091824 =
  'SECOND_PAGE_AUTO_MODAL_091824' as SettingConst<
    'SECOND_PAGE_AUTO_MODAL_091824',
    boolean,
    'RETAILER' | 'VISITOR_WEB'
  >;
