import { experiment } from "@faire/web--source/common/settings/declarations/experiment";

/**
 * Discover page SEM value props experiment
 */
export const FE_SIMPLIFIED_SEM_DISCOVER_V2_250303 = experiment({
  name: "FE_SIMPLIFIED_SEM_DISCOVER_V2_250303",
  identifier: "SessionToken",
  client: "RETAILER",
  notifyAfter: "2025-05-03",
  defaultValue: false,
  productArea: "LOGGED_OUT_RETAILERS",
});
