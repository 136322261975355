"use client";

import { useViewportWidth } from "@faire/web--source/ui/hooks/useViewportWidth";
import { useEffect } from "react";
import { COOKIE_CLIENT_WIDTH } from "@faire/web--source/common/consts/COOKIE_CLIENT_WIDTH";
import { setCookies } from "@faire/web--source/common/cookies";


export const ClientViewport = () => {
    const width = useViewportWidth(width => width);
    useEffect(() => {
        if(isNaN(width) === false && width > 0) {
            setCookies(COOKIE_CLIENT_WIDTH, width);
        }
    }, [width]);
    return null;   
}