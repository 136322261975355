"use client";

import {
  createContext,
  startTransition,
  useContext,
  useEffect,
  useState,
} from "react";

type Context = {
  showContent: boolean;
};

const DeferredRenderContext = createContext<Context>({
  showContent: false,
});

export const useShowDeferredContent = () => {
  return useContext(DeferredRenderContext).showContent;
};

type Props = {
  children: React.ReactNode;
};

export const DeferredRenderContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [showContent, setShowContent] = useState(false);

  /**
   * Wait for 100ms to allow React to hydrate / render most of the initial content.
   * Then, start a transition to render the deferred content.
   */
  useEffect(() => {
    setTimeout(() => {
      startTransition(() => {
        setShowContent(true);
      });
    }, 100);
  }, []);

  return (
    <DeferredRenderContext.Provider value={{ showContent }}>
      {children}
    </DeferredRenderContext.Provider>
  );
};
