import { SettingConst } from '@faire/web-api--source/types';

/**
 * Enables new bottom sheet modal nudge for app downloads
 * https://www.notion.so/faire/App-NUX-App-Download-Nudges-f5833b4d1b1d42af8886b153a1473b30#1182efb5c25a807c8c4ecf31cff64bf5
 */
export const NUX_APP_DOWNLOAD_NUDGES_241015 =
  'NUX_APP_DOWNLOAD_NUDGES_241015' as SettingConst<
    'NUX_APP_DOWNLOAD_NUDGES_241015',
    boolean,
    'RETAILER' | 'HOTJAR_RETAILER' | 'VISITOR_WEB' | 'HOTJAR_VISITOR_WEB'
  >;
