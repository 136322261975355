import { SettingConst } from '@faire/web-api--source/types';

/**
 * Controls whether or not a retailer is part of the improved FOI experiment
 * https://www.notion.so/faire/FOI-experience-improvements-406098ebeef1452cadcbb6db17e6d15c
 */
export const IMPROVED_FOI_EXPERIENCE_241029 =
  'IMPROVED_FOI_EXPERIENCE_241029' as SettingConst<
    'IMPROVED_FOI_EXPERIENCE_241029',
    boolean,
    | 'RETAILER'
    | 'RETAILER_ANDROID'
    | 'RETAILER_IOS'
    | 'HOTJAR_RETAILER'
    | 'VISITOR_WEB'
    | 'HOTJAR_VISITOR_WEB'
  >;
