import {
  useAssignSetting,
  useSetting,
} from "@faire/retailer-visitor-shared/settings/__internal__/useSetting";
import { NUX_APP_DOWNLOAD_NUDGES_241015 } from "@faire/web-api--source/indigofair/settings/NUX_APP_DOWNLOAD_NUDGES_241015";

export const useSettingNuxAppDownloadNudges = (): boolean =>
  useSetting(NUX_APP_DOWNLOAD_NUDGES_241015, false);

export const useAssignSettingNuxAppDownloadNudges = () =>
  useAssignSetting(NUX_APP_DOWNLOAD_NUDGES_241015);
