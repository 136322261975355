"use client";

import { getFaireDirectBrandAlias } from "@faire/retailer-visitor-shared/serialized-data/getFaireDirectBrandAlias";
import { getFaireDirectBrandToken } from "@faire/retailer-visitor-shared/serialized-data/getFaireDirectBrandToken";
import { getTwoLetterLanguageCode } from "@faire/web--source/common/consts/THREE_TO_TWO_LETTER_LANGUAGE_CODE";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { convertLocaleToCountryLocaleString } from "@faire/web--source/common/localization/languageWithCountryOptions";
import { useLocalization } from "@faire/web--source/common/localization/useLocalization";
import { isPrerender } from "@faire/web--source/common/user-agent/isPrerender";
import { NextLink } from "@faire/web--source/ui/NextLink";
import { RoutingContext } from "@faire/web--source/ui/routing/RoutingContext";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ILocaleKey } from "@faire/web-api--source/indigofair/locale/ILocaleKey";
import React from "react";

export const RetailerRoutingContextProvider: React.FC<{
  useNextLink?: boolean;
  children: React.ReactNode;
}> = (props) => {
  const {
    intl: { locale },
  } = useLocalization();
  const canonicalLocale = getTwoLetterLanguageCode(Language.ENG).toLowerCase();
  const localeCountryUrlPrefix: string | undefined = getGlobalProperty(
    "localeCountryUrlPrefix"
  );
  const availableLanguageSelectorLocales = (
    getGlobalProperty<ILocaleKey[]>("availableLanguageSelectorLocales") ?? []
  ).map((iLocaleKey) => convertLocaleToCountryLocaleString(iLocaleKey));

  const faireDirectBrandToken = getFaireDirectBrandToken();
  const faireDirectBrandAlias = getFaireDirectBrandAlias();

  return (
    <RoutingContext.Provider
      value={{
        locale,
        canonicalLocale,
        localeCountryUrlPrefix,
        allowedRobotLocales: availableLanguageSelectorLocales,
        ...(!isPrerender()
          ? { faireDirectBrandAlias, faireDirectBrandToken }
          : null),
        Link: props.useNextLink ? NextLink : undefined,
      }}
    >
      {props.children}
    </RoutingContext.Provider>
  );
};

export const useRetailerRoutingContext = () => {
  return React.useContext(RoutingContext);
};
