import { request } from '@faire/web-api--source';
import { IDisplayPasswordModalResponse } from '@faire/web-api--source/indigofair/data/IDisplayPasswordModalResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/v2/users/display-password-modal`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/users/display-password-modal';

/**
 * Product Area: USERS
 */
function execute(): Promise<IDisplayPasswordModalResponse>;
function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IDisplayPasswordModalResponse>>;
function execute(
  options?: EndpointOptions
): Promise<IDisplayPasswordModalResponse>;
function execute(
  options?: EndpointOptions
): Promise<
  IDisplayPasswordModalResponse | SuccessResponse<IDisplayPasswordModalResponse>
> {
  const { route, ...requestOptions } = build(options);
  return request<IDisplayPasswordModalResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/v2/users/display-password-modal/get';
export default execute;
