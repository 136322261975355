import { isLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { getSettingRscLoggedOutSearchPage } from "@faire/retailer-visitor-shared/settings/getSettingRscLoggedOutSearchPage";
import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { QueryParameters as SearchQueryParameters } from "@faire/web-api--source/routes/www/search";

export const shouldShowRscLoggedOutSearchPage = () => {
  const search = getLocationOrThrow().search;
  const rscParam = (
    SearchQueryParameters.parse(search) as Record<string, string | undefined>
  ).rsc;
  return getSettingRscLoggedOutSearchPage(rscParam) && !isLoggedInRetailer();
};
