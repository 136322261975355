import { useInvalidateServerCartSummary } from "@faire/retailer-visitor-shared/features/Cart/ServerCart/useInvalidateServerCartSummary";
import { useIsLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { getCartToken } from "@faire/retailer-visitor-shared/serialized-data/getCartToken";
import { QueryParameters } from "@faire/web-api--source/endpoints/www/api/v2/carts/cartToken/summary/get";
import {
  useQuery as useCartSummaryQuery,
  useInvalidateQuery as useInvalidateCartSummaryQuery,
  usePrefetchQuery as usePrefetchCartSummaryQuery,
} from "@faire/web-api--source/endpoints/www/api/v2/carts/cartToken/summary/get-hooks";
import { IBrandCart } from "@faire/web-api--source/faire/carts/IBrandCart";
import { ICartSummaryResponseV2 } from "@faire/web-api--source/faire/carts/ICartSummaryResponseV2";
import { useCallback, useMemo } from "react";

type Arg = {
  brandTokens?: string[];
};

export const useEntireCartSummary = (arg?: Arg) => {
  const {
    data: activeCartSummaryData,
    isPending: isActiveCartSummaryPending,
    isLoading: isActiveCartSummaryLoading,
    isFetching: isActiveCartSummaryFetching,
  } = useActiveCartSummary(arg);

  const {
    data: savedCartSummaryData,
    isPending: isSavedCartSummaryPending,
    isLoading: isSavedLoading,
    isFetching: isSavedFetching,
  } = useSavedCartSummary(arg);

  const entireSummaryData = useMemo(
    () =>
      ICartSummaryResponseV2.build({
        ...activeCartSummaryData,
        brand_cart_summaries: {
          ...activeCartSummaryData?.brand_cart_summaries,
          ...savedCartSummaryData?.brand_cart_summaries,
        },
      }),
    [activeCartSummaryData, savedCartSummaryData?.brand_cart_summaries]
  );

  return {
    data: entireSummaryData,
    isPending: isActiveCartSummaryPending || isSavedCartSummaryPending,
    isLoading: isActiveCartSummaryLoading || isSavedLoading,
    isFetching: isActiveCartSummaryFetching || isSavedFetching,
  };
};

export const useSavedCartSummary = (arg?: Arg) => {
  const isLoggedInRetailer = useIsLoggedInRetailer();

  return useCartSummaryQuery(
    getCartToken(),
    QueryParameters.build({
      brand_tokens: arg?.brandTokens ?? [],
      brand_cart_state: IBrandCart.State.SAVED_FOR_LATER,
    }),
    {
      enabled: isLoggedInRetailer,
      refetchOnMount: false,
    }
  );
};

export const useActiveCartSummary = (arg?: Arg) => {
  const isLoggedInRetailer = useIsLoggedInRetailer();

  return useCartSummaryQuery(
    getCartToken(),
    QueryParameters.build({
      brand_tokens: arg?.brandTokens ?? [],
      brand_cart_state: IBrandCart.State.ACTIVE,
    }),
    {
      enabled: isLoggedInRetailer,
      refetchOnMount: false,
    }
  );
};

export const useInvalidateCartSummary = (arg?: Arg) => {
  const invalidateCartSummaryQuery = useInvalidateCartSummaryQuery();

  const {
    invalidateActiveServerCartSummary,
    invalidateSavedServerCartSummary,
  } = useInvalidateServerCartSummary();

  const invalidateCartSummaryData = useCallback(
    (brandCartState: IBrandCart.State) =>
      invalidateCartSummaryQuery(
        getCartToken(),
        QueryParameters.build({
          brand_tokens: arg?.brandTokens ?? [],
          brand_cart_state: brandCartState,
        }),
        undefined,
        { refetchType: "all" }
      ),
    [arg?.brandTokens, invalidateCartSummaryQuery]
  );

  const invalidateActiveCartSummary = useCallback(async () => {
    const promises: Promise<void>[] = [
      invalidateActiveServerCartSummary(arg?.brandTokens ?? []),
      invalidateCartSummaryData(IBrandCart.State.ACTIVE),
    ];
    return await Promise.all(promises);
  }, [
    arg?.brandTokens,
    invalidateActiveServerCartSummary,
    invalidateCartSummaryData,
  ]);

  const invalidateSavedCartSummary = useCallback(async () => {
    const promises: Promise<void>[] = [
      invalidateSavedServerCartSummary(arg?.brandTokens ?? []),
      invalidateCartSummaryData(IBrandCart.State.SAVED_FOR_LATER),
    ];

    return await Promise.all(promises);
  }, [
    arg?.brandTokens,
    invalidateCartSummaryData,
    invalidateSavedServerCartSummary,
  ]);

  const invalidateEntireCartSummary = useCallback(async () => {
    return await Promise.all([
      invalidateActiveCartSummary(),
      invalidateSavedCartSummary(),
    ]);
  }, [invalidateActiveCartSummary, invalidateSavedCartSummary]);

  return {
    invalidateEntireCartSummary,
    invalidateActiveCartSummary,
    invalidateSavedCartSummary,
  };
};

export const usePrefetchCartSummary = (arg?: Arg) => {
  const prefetchCartSummary = usePrefetchCartSummaryQuery();

  const prefetchActiveCartSummary = useCallback(() => {
    return prefetchCartSummary(
      getCartToken(),
      QueryParameters.build({
        brand_tokens: arg?.brandTokens ?? [],
        brand_cart_state: IBrandCart.State.ACTIVE,
      })
    );
  }, [arg?.brandTokens, prefetchCartSummary]);

  return { prefetchActiveCartSummary };
};
