import { setDefaultTitleTag } from "@faire/retailer-visitor-shared/components/App/DefaultTitleTags";
import { useSynchronizePromoBanners } from "@faire/retailer-visitor-shared/components/App/useSynchronizePromoBanners";
import { useNationality } from "@faire/retailer-visitor-shared/hooks/retailer/useNationality";
import { useRetailerFinancial } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailerFinancial";
import { configureCurrency } from "@faire/retailer-visitor-shared/lib/currency";
import { getCurrencyConversionRate } from "@faire/retailer-visitor-shared/serialized-data/getCurrencyConversionRate";
import { getSessionCountry } from "@faire/retailer-visitor-shared/serialized-data/getSessionCountry";
import { getUser } from "@faire/retailer-visitor-shared/serialized-data/getUser";
import { useAssignSettingCheqAIImpactTest } from "@faire/retailer-visitor-shared/settings/useAssignSettingCheqAIImpactTest";
import { useAssignSettingRSCTopOfPageContent } from "@faire/retailer-visitor-shared/settings/useAssignSettingRSCTopOfPageContent";
import { useAssignSettingNextJSRouterCache } from "@faire/retailer-visitor-shared/settings/useSettingNextJSRouterCache";
import { useCartInitialization } from "@faire/retailer-visitor-shared/useCartInitialization";
import { useInitializeLocalization } from "@faire/retailer-visitor-shared/useInitializeLocalization";
import { useCurrencyStore } from "@faire/web--source/common/currency/CurrencyStore";
import { getWindowOrThrow } from "@faire/web--source/common/globals/getWindow";
import { useStrictLocalization } from "@faire/web--source/common/localization";
import useMemoryMonitor from "@faire/web--source/common/performance/trackMemoryUsage";
import { useAdblockDetector } from "@faire/web--source/ui/AdblockDetector";
import { trackSessionsViewportRetailerPageViewView } from "@faire/web-api--source/events/sessions/view/viewportRetailerPageView";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const AppEffects = () => {
  useCartInitialization();
  useSynchronizePromoBanners();
  const currencyConversionRate = getCurrencyConversionRate();
  const sessionCountry = getSessionCountry();
  const { token: userToken } = getUser() ?? {};
  const { strictLocalize } = useStrictLocalization();
  const { pathname } = useLocation();
  const { isNewOrMigratedCanadian, isEuropeNonUk, isUK } = useNationality();
  const { currency } = useRetailerFinancial();
  const { setCurrency } = useCurrencyStore(["setCurrency"]);

  const assignSettingNextJSRouterCache = useAssignSettingNextJSRouterCache();
  const assignSettingRSCTopOfPageContent =
    useAssignSettingRSCTopOfPageContent();
  const assignSettingCheqAIImpactTest = useAssignSettingCheqAIImpactTest();

  useEffect(() => {
    assignSettingNextJSRouterCache();
  }, [assignSettingNextJSRouterCache]);

  useEffect(() => {
    assignSettingRSCTopOfPageContent();
  }, [assignSettingRSCTopOfPageContent]);

  useEffect(() => {
    assignSettingCheqAIImpactTest();
  }, [assignSettingCheqAIImpactTest]);

  useAdblockDetector(userToken);
  useInitializeLocalization();

  useEffect(() => {
    configureCurrency(
      sessionCountry,
      currencyConversionRate,
      {
        isEuropeNonUk,
        isUK,
      },
      currency
    );
  }, [currencyConversionRate, sessionCountry, isEuropeNonUk, isUK, currency]);

  useEffect(() => {
    // TODO(bocong.zhao@faire.com, 2022/11/01): Remove this when we fully relaunch Canada
    // This is to clean up the localstorage if it's CAN with CAD (migrated Canadian retailer)
    if (isNewOrMigratedCanadian) {
      setCurrency(undefined);
    }
  }, [isNewOrMigratedCanadian, setCurrency]);

  useEffect(() => {
    setDefaultTitleTag(strictLocalize);
  }, [strictLocalize]);

  /** Log viewport on path changge. */
  useEffect(() => {
    trackSessionsViewportRetailerPageViewView(
      pathname,
      `${getWindowOrThrow().innerWidth}`,
      `${getWindowOrThrow().innerHeight}`
    );
  }, [pathname]);

  useMemoryMonitor("web-retailer");

  return null;
};
