// This custom cache doesn't support query params and only single pathname
"use client";

import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { getPathnameWithoutLocale } from "@faire/web--source/ui/routing/util";
import { useCallback } from "react";
import { useSettingNextJSRouterCacheEnabled } from "@faire/retailer-visitor-shared/settings/useSettingNextJSRouterCache";

type CustomRouterCacheConfig = {
    staleTime?: number;
    lastUpdated?: number;
    lastRandomQueryParam?: string;
}

const getRandomString = () => {
    return Math.random().toString(36).substring(2, 6);
}

const CUSTOM_ROUTER_CACHE_QUERY_PARAM = "frc";
// This is number from NextJS config dynamic stale time and should be kept in sync
// TODO: Hamidreza to update and read from ENV variables
const DEFAULT_NEXTJS_STALE_TIME = 30;

const CustomRouterConfigMap = new Map<string, CustomRouterCacheConfig>([
    ["/", {
        staleTime: 1000 * 60, // 1 minute
        lastUpdated: 0,
        lastRandomQueryParam: "",
    }],
]);

export const useCustomRouterCache = (overrideDefaultStaleTime?: number) => {
    const isCustomRouterCacheEnabled = useSettingNextJSRouterCacheEnabled();
    const nextRouterStaleTime = overrideDefaultStaleTime ? overrideDefaultStaleTime * 1000 : (Number(getGlobalProperty("faireDynamicStaleTime")) || DEFAULT_NEXTJS_STALE_TIME) * 1000;
    const getModifiedUrl = useCallback((url: string) => {
        if (!isCustomRouterCacheEnabled) {
            return url;
        }
        const pathname = getPathnameWithoutLocale(url);
        const config = pathname ? CustomRouterConfigMap.get(pathname) : undefined;
        if (!config) {
            return url;
        }
        if (!config.lastUpdated) {
            config.lastUpdated = Date.now();
            return url;
        }
        if (config.lastUpdated && config.staleTime) {
            const timeSinceLastUpdate = Date.now() - config.lastUpdated;
            const isStale = timeSinceLastUpdate < nextRouterStaleTime && timeSinceLastUpdate > config.staleTime;
            if (isStale) {
                config.lastRandomQueryParam = getRandomString();
                config.lastUpdated = Date.now();
            }
        }
        if (config.lastRandomQueryParam) {
            const [base, existingQuery] = url.split('?');
            const searchParams = new URLSearchParams(existingQuery);
            searchParams.set(CUSTOM_ROUTER_CACHE_QUERY_PARAM, config.lastRandomQueryParam);
            return base + "?" + searchParams.toString();
        }
        return url;
    }, [nextRouterStaleTime, isCustomRouterCacheEnabled]);
    return {
        getModifiedUrl,
    }
}