import type { Span, Attributes, SpanOptions } from "@opentelemetry/api";

import { getSettingEnableDetailedSessionTracing } from "../settings/getSettingEnableDetailedSessionTracing";

import { TelemetryManagerNoOp } from "./TelemetryManagerNoOp";

export interface ITelemetryHeaders {
  DD_HEADER_TRACE?: string | undefined;
  DD_HEADER_SPAN?: string | undefined;
  DD_HEADER_SAMPLED?: string | undefined;
  DD_HEADER_ORIGIN?: string | undefined;
  traceparent?: string | undefined;
  tracestate?: string | undefined;
}
export interface ITelemetryManager {
  initialize(serviceName: string, consoleProvider?: boolean): void;

  startTrace(
    name: string,
    attributes?: Attributes,
    options?: SpanOptions,
    useSerializedTraceContext?: boolean
  ): Span | undefined;

  endTrace(name?: string, attributes?: Attributes): void;

  getActiveTraceHeaders(): ITelemetryHeaders | undefined;

  getActiveTraceId(): string | undefined;

  startSpan(
    name: string,
    attributes?: Attributes,
    parentSpan?: Span,
    root?: boolean
  ): Span | undefined;

  endSpan(span: Span | undefined): void;

  startManagedSpan(
    name: string,
    attributes?: Attributes,
    options?: SpanOptions
  ): string | undefined;

  endManagedSpan(spanId: string, attributes?: Attributes): void;

  getManagedSpan(spanId: string): Span | null;

  getSpanRequestHeaders(span?: string | Span): ITelemetryHeaders | undefined;
}

/**
 * TelemetryManager is a singleton class that manages the OpenTelemetry
 * provider and tracer. It provides methods to start and end traces and spans,
 * and to get the headers for the active trace or span.
 * It also provides methods to start, end, and get headers for managed spans.
 * Managed spans are spans that are created by the TelemetryManager
 * and are not directly associated with the current context.
 */
export class TelemetryManager {
  private static instance: ITelemetryManager | null = null;
  private static otelPromise: Promise<ITelemetryManager> | null = null;

  // Add a method to wait for the async loading to complete (for testing)
  static waitForInitialization = async (): Promise<void> => {
    if (this.otelPromise) {
      await this.otelPromise;
    }
  };

  static get = (): ITelemetryManager => {
    if (!this.instance) {
      if (getSettingEnableDetailedSessionTracing()) {
        if (!this.otelPromise) {
          this.otelPromise = import("./TelemetryManagerOtel")
            .then(({ TelemetryManagerOtel }) => {
              this.instance = new TelemetryManagerOtel();
              return this.instance;
            })
            .catch(() => {
              this.instance = new TelemetryManagerNoOp();
              return this.instance;
            });
        }
        // Return a NoOp instance while loading
        return new TelemetryManagerNoOp();
      } else {
        this.instance = new TelemetryManagerNoOp();
      }
    }
    return this.instance;
  };

  static reset = (): void => {
    this.instance = null;
    this.otelPromise = null;
  };
}
