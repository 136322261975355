"use client";

/* eslint-disable @faire/ssr-no-restricted-globals */
import { scopedSingletonGetter } from "@faire/retailer-visitor-shared/app/_lib/scopedSingletonGetter";
import { SSRRequestLocalStorage } from "@faire/retailer-visitor-shared/app/_lib/SSRRequestLocalStorage";
import { LOCAL_PORT } from "@faire/web--source/common/routes/faireBaseUrl";
import {
  faireBaseUrlMatcher,
  Environment,
} from "@faire/web--source/common/routes/faireBaseUrlMatcher";
import { handleHydrationCompleted } from "@faire/web--source/common/server-side-rendering/isSSR";
import { createMemoryHistory, MemoryHistory } from "history";
import React, { useLayoutEffect, useRef } from "react";

import { init as initializeWebApi } from "@faire/retailer-visitor-shared/initialize/web-api";

export const Globals = ({
  children,
  globals,
  memoryHistory,
  userAgent,
  referer,
  originalUrl,
}: {
  children: React.ReactNode;
  globals: Record<string, unknown>;
  memoryHistory: MemoryHistory<unknown>;
  userAgent: string | null;
  referer: string | null;
  originalUrl: string | undefined;
}) => {
  const didInit = useRef(false);

  useLayoutEffect(() => {
    // We need to call this in a layout effect to notify our code that Hydration
    // finished and code can start using client side value. One example is
    // ObservableWindow
    handleHydrationCompleted();
  }, []);

  if (typeof window === "undefined") {
    // Use the location according to the Faire backend, not according to Next.js.
    // Some differences: Next.js always includes a URL and doesn't ever end in a slash.
    let url = new URL(
      "/",
      faireBaseUrlMatcher(globals.envName as Environment, "retailer")
    );
    try {
      if (originalUrl) {
        url = new URL(originalUrl);
      } else {
        // eslint-disable-next-line no-console
        console.error("originalUrl is undefined");
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error parsing originalUrl", { originalUrl, error: e });
    }
    if (globals.envName === "local") {
      // To avoid hydration errors, we need to set the URL to localhost instead of staging
      // during local development.
      url = new URL(
        `${url.pathname}${url.search}`,
        `http://localhost:${LOCAL_PORT.retailer}`
      );
    }
    const reactRouterLocation = createMemoryHistory({
      initialEntries: [`${url.pathname}${url.search}`],
    }).location;
    const location: Location = {
      href: url.href,
      hostname: url.hostname,
      host: url.host,
      origin: url.origin,
      port: url.port,
      protocol: url.protocol,
      ancestorOrigins: [] as never as DOMStringList,
      assign() {},
      reload() {},
      replace() {},
      ...reactRouterLocation,
    };

    // Note that since this is rendered on the server, this will not be
    // rerendered. Recreating the globals on the client every render wouldn't
    // necessarily be safe.
    return (
      <SSRRequestLocalStorage.Provider
        value={{
          global: {
            ...globals,
            isSSR: true,
            isNextJS: true,
            needToHydrate: true,
            location,
            memoryHistory,
            window: {
              location,
              navigator: {
                userAgent,
              },
              document: {
                referrer: referer ?? "",
              },
              addEventListener: () => {},
            },
            faire: {
              singletons: {
                getter: scopedSingletonGetter,
              },
              api: {
                // TODO(jocelyn): We probably don't actually want to silently resolve fetches,
                // but we currently assume that fetches work (e.g., when assigning).
                // Investigate and consider removing.
                handler: () => Promise.resolve(),
              },
              app: "web-retailer",
            },
          },
        }}
      >
        {children}
      </SSRRequestLocalStorage.Provider>
    );
  } else {
    // This isn't an effect, because we need this to run before any other
    // code. (Please don't copy this code into other files, this is a hack.)
    if (!didInit.current) {
      for (const [key, value] of Object.entries(globals)) {
        // @ts-expect-error -- These are globals (we want to get rid of globals).
        // Note that "faire" is initialized earlier, in `indexHead.ts`.
        if (key !== "faire" || !window[key]) {
          // @ts-expect-error -- These are globals (we want to get rid of globals).
          window[key] = value; // eslint-disable-line react-compiler/react-compiler
        }
      }
      didInit.current = true;

      // @ts-expect-error -- These are globals (we want to get rid of globals).
      window["isNextJS"] = true;

      // @ts-expect-error -- These are globals (we want to get rid of globals).
      window["cache"] = {};

      initializeWebApi();
      didInit.current = true;
    }

    return <>{children}</>;
  }
};
