import { UXCam } from "@faire/web--source/common/uxcam/uxcam";
import { TrackedEvent } from "@faire/web-api--source/types";

export const uxcamInterceptor = (params: TrackedEvent) => {
  if (params.bypassSessionRecording) {
    // Do not pass event to Hotjar if bypassing session recording
    return;
  }
  try {
    UXCam.event(params.eventKey, params.parameters);
  } catch (_) {
    /* ignore */
  }
};
