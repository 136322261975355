"use client";

import { useCustomRouterCache } from "@faire/retailer-visitor-shared/app/_lib/routing/CustomRouterCache/useCustomRouterCache";
import {
  ModifiedNavigateOptions,
  ModifiedNextRouter,
  ModifiedPrefetchOptions,
} from "@faire/retailer-visitor-shared/app/_lib/routing/types";
import { useSettingNextJSRouterCacheEnabled } from "@faire/retailer-visitor-shared/settings/useSettingNextJSRouterCache";
import { getWindowOrThrow } from "@faire/web--source/common/globals/getWindow";
import { RoutingContext } from "@faire/web--source/ui/routing/RoutingContext";
import { prependLocaleToUrl } from "@faire/web--source/ui/routing/util";
import { trackWebRetailerServerNextjsRouterCacheHit } from "@faire/web-api--source/events/webRetailerServer/actionUnknown/nextjsRouterCacheHit";
import { PrefetchKind } from "next/dist/client/components/router-reducer/router-reducer-types";
// eslint-disable-next-line no-restricted-imports
import { useRouter as useNextRouter } from "next/navigation";
import { useCallback, useContext, useMemo } from "react";

import { NextCacheTrackingContext } from "./NextCacheTrackingContextProvider";

/**
 * A wrapper around next/navigation's useRouter that prepends the locale to the href and allows for shallow routing.
 * @example
 * ```tsx
 * const router = useRouter();
 * router.push('/?signIn=1');
 * router.push('/?signIn=1', { shallow: true });
 * ```
 */
export const useRouter = (): ModifiedNextRouter => {
  const nextRouter = useNextRouter();
  const routingContext = useContext(RoutingContext);
  const nextCacheTrackingContext = useContext(NextCacheTrackingContext);
  const { getModifiedUrl } = useCustomRouterCache();
  const isNextJSRouterCacheEnabled = useSettingNextJSRouterCacheEnabled();

  const checkAndUpdateCache = useCallback(
    (href: string, previousPathname?: string) => {
      // 5 minutes for custom router cache, 1 minute for default router cache
      const CACHE_TTL = isNextJSRouterCacheEnabled ? 1000 * 60 * 5 : 1000 * 60;

      if (nextCacheTrackingContext.possibleCachedHrefs?.current?.[href]) {
        if (
          (nextCacheTrackingContext.possibleCachedHrefs.current[href] ?? 0) +
            CACHE_TTL >
          Date.now()
        ) {
          trackWebRetailerServerNextjsRouterCacheHit(
            href,
            "hit",
            previousPathname ?? ""
          );
        } else {
          trackWebRetailerServerNextjsRouterCacheHit(
            href,
            "miss",
            previousPathname ?? ""
          );
        }
      } else {
        trackWebRetailerServerNextjsRouterCacheHit(
          href,
          "miss",
          previousPathname ?? ""
        );
      }
      if (nextCacheTrackingContext.possibleCachedHrefs?.current) {
        // eslint-disable-next-line react-compiler/react-compiler
        nextCacheTrackingContext.possibleCachedHrefs.current[href] = Date.now();
      }
    },
    [isNextJSRouterCacheEnabled, nextCacheTrackingContext]
  );

  const push = useCallback(
    (href: string, options?: ModifiedNavigateOptions) => {
      const hrefWithLocale = prependLocaleToUrl(href, routingContext);
      const { history } = getWindowOrThrow();

      if (options?.shallow) {
        checkAndUpdateCache(hrefWithLocale, options.previousPathname);
        history.pushState.call(history, null, "", hrefWithLocale);
      } else {
        checkAndUpdateCache(hrefWithLocale, options?.previousPathname);
        nextRouter.push(hrefWithLocale, options);
      }
    },
    [routingContext, checkAndUpdateCache, nextRouter]
  );

  const replace = useCallback(
    (href: string, options?: ModifiedNavigateOptions) => {
      const hrefWithLocale = prependLocaleToUrl(href, routingContext);
      const { history } = getWindowOrThrow();

      if (options?.shallow) {
        checkAndUpdateCache(hrefWithLocale, options.previousPathname);
        history.replaceState.call(history, null, "", hrefWithLocale);
      } else {
        checkAndUpdateCache(hrefWithLocale, options?.previousPathname);
        nextRouter.replace(hrefWithLocale, options);
      }
    },
    [routingContext, checkAndUpdateCache, nextRouter]
  );

  const prefetch: typeof nextRouter.prefetch = useCallback(
    (href, options?: ModifiedPrefetchOptions) => {
      const hrefWithLocale = prependLocaleToUrl(href, routingContext);
      const modifiedUrl = getModifiedUrl(hrefWithLocale);

      if (options?.kind === PrefetchKind.FULL && !options?.skipCacheUpdate) {
        if (nextCacheTrackingContext.possibleCachedHrefs?.current) {
          nextCacheTrackingContext.possibleCachedHrefs.current[modifiedUrl] =
            Date.now();
        }
      }
      return nextRouter.prefetch(modifiedUrl, options);
    },
    [routingContext, getModifiedUrl, nextRouter, nextCacheTrackingContext]
  );

  const modifiedRouter = useMemo(
    () => ({
      push,
      replace,
      prefetch,
      refresh: nextRouter.refresh,
      back: nextRouter.back,
      forward: nextRouter.forward,
    }),
    [push, replace, prefetch, nextRouter]
  );

  return modifiedRouter;
};
