import { useIsAppInstalled } from "@faire/retailer-visitor-shared/hooks/pageData/useIsAppInstalled";
import { getOsName } from "@faire/retailer-visitor-shared/lib/user-agent/osName";
import {
  getDeepLinkUrl,
  OSType,
} from "@faire/retailer-visitor-shared/services/MobileAppBanner";
import { useSettingAppPromotionBannerLinks } from "@faire/retailer-visitor-shared/settings/useSettingAppPromotionBannerLinks";
import { RawQueryParams } from "@faire/web--source/common/query-params/QueryParams";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useGetMobileAppUrl = (queryParams?: RawQueryParams) => {
  const location = useLocation();
  const storeLinks = useSettingAppPromotionBannerLinks();
  const isAppInstalled = useIsAppInstalled();

  const deepLinkUrl = getDeepLinkUrl(location, queryParams);
  const storeLinkUrl = useMemo(() => {
    if (getOsName() === OSType.IOS) {
      return storeLinks.app_store_link ?? "";
    } else if (getOsName() === OSType.ANDROID) {
      return storeLinks.play_store_link ?? "";
    }

    return "";
  }, [storeLinks.app_store_link, storeLinks.play_store_link]);

  const ctaUrl = useMemo(
    (): string => (!!isAppInstalled && deepLinkUrl) || storeLinkUrl,
    [deepLinkUrl, isAppInstalled, storeLinkUrl]
  );

  return ctaUrl;
};
