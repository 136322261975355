import { IAppPromotionBannerLinks } from '@faire/web-api--source/indigofair/data/IAppPromotionBannerLinks';
import { SettingConst } from '@faire/web-api--source/types';

/**
 * TBD
 */
export const APP_PROMOTION_BANNER_LINKS =
  'APP_PROMOTION_BANNER_LINKS' as SettingConst<
    'APP_PROMOTION_BANNER_LINKS',
    IAppPromotionBannerLinks,
    'VISITOR_WEB' | 'RETAILER' | 'RETAILER_HEAD_TAGS' | 'VISITOR_WEB_HEAD_TAGS'
  >;
