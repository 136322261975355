import { isMobileApp } from "@faire/retailer-visitor-shared/lib/isMobileApp";
import { getNavigator } from "@faire/web--source/common/globals/getNavigator";

// Android Official Source: https://developer.chrome.com/docs/multidevice/user-agent/#webview-on-android

const rules = [
  // if it says it's a webview, let's go with that
  /WebView/gim,
  // < Android 4.4 (Kitkat)
  /Linux; U; Android/gim,
  // >= Android 5.1.1 (Lollipop)
  /Android.*;\s+wv/gim,
];
// >= Android 4.4 (Kitkat) && < Android 5.1.1 (Lollipop)
const androidKitKatToLollipop = /Version\/\d.\d\s+Chrome\/\d+(\.0){3}/gim;

const isIOSWebView = (userAgent: string) => {
  return /webview|wv|ip((?!.*Safari|.*Fxi)|(?=.*like Safari))/i.test(userAgent);
};

export const isWebView = () => {
  const userAgent = getNavigator()?.userAgent;
  if (!userAgent) {
    return false;
  }

  return (
    rules.some((rule) => userAgent.match(rule)) ||
    (userAgent.includes("Android") &&
      userAgent.match(androidKitKatToLollipop)) ||
    isMobileApp() ||
    isIOSWebView(userAgent)
  );
};
