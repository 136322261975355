import { isLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { getSettingRscLibp } from "@faire/retailer-visitor-shared/settings/getSettingRscLibp";
import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { QueryParameters as SearchQueryParameters } from "@faire/web-api--source/routes/www/search";

export const shouldShowRscLibp = () => {
  if (!isLoggedInRetailer()) {
    return false;
  }
  const search = getLocationOrThrow().search;
  const rscParam = (
    SearchQueryParameters.parse(search) as Record<string, string | undefined>
  ).rsc;
  return getSettingRscLibp(rscParam);
};
