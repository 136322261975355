import { HAS_CLOSED_SIGNUP_MODAL } from "@faire/retailer-visitor-shared/components/AppSignUp/state/consts";
import {
  notifySignupSnackbarEvent,
  SignUpSnackbarEvent,
} from "@faire/retailer-visitor-shared/components/LoggedOutMobileSignUpSnackbar/useSignupSnackbarStateMachine";
import { isLoggedInRetailerOrBrandPreview } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { isComingFromFacebookOrInstagram } from "@faire/retailer-visitor-shared/lib/navigation-origin/isComingFromFacebookOrInstagram";
import { isComingFromSEM } from "@faire/retailer-visitor-shared/lib/navigation-origin/isComingFromSEM";
import {
  popSignUp,
  SignUpElement,
  SignUpType,
} from "@faire/retailer-visitor-shared/lib/popSignUp";
import { AppHistory } from "@faire/retailer-visitor-shared/packages/core/services/AppHistory";
import { getIsProduction } from "@faire/retailer-visitor-shared/serialized-data/getEnvName";
import { getFaireDirectBrandToken } from "@faire/retailer-visitor-shared/serialized-data/getFaireDirectBrandToken";
import { getIsRobot } from "@faire/retailer-visitor-shared/serialized-data/getIsRobot";
import {
  assignSettingSecondPageAutoModal,
  getSettingSecondPageAutoModal,
} from "@faire/retailer-visitor-shared/settings/getSettingSecondPageAutoModal";
import { getSettingSimplifiedSemDiscoverV2 } from "@faire/retailer-visitor-shared/settings/getSettingSimplifiedSemDiscoverV2";
import { AppModalStore } from "@faire/retailer-visitor-shared/stores/ui/AppModalStore";
import { delay } from "@faire/web--source/common/delay";
import {
  getWindow,
  getWindowOrThrow,
} from "@faire/web--source/common/globals/getWindow";
import { RouteMatcher } from "@faire/web--source/common/routes/RouteMatcher";
import { Storage } from "@faire/web--source/common/Storage";
import { removeLocaleFromUrl } from "@faire/web--source/ui/routing/util";
import { route as brandRoute } from "@faire/web-api--source/routes/www/brand/brandToken";
import { route as c1Route } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId";
import { route as c1BrandValueRoute } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/brand-value/brandValue";
import { route as c2Route } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/subcategory/c2CategoryUrlId";
import { route as c2BrandValueRoute } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/brand-value/brandValue";
import { route as c3Route } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId";
import { route as c3BrandValueRoute } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/brand-value/brandValue";
import { route as discoverRoute } from "@faire/web-api--source/routes/www/discover/searchTerm";
import { route as exploreRoute } from "@faire/web-api--source/routes/www/explore/searchTerm";
import { route as collectionRoute } from "@faire/web-api--source/routes/www/product-based-collection/product_based_collection_token";
import { route as productRoute } from "@faire/web-api--source/routes/www/product/productToken";
import { route as homeRoute } from "@faire/web-api--source/routes/www/root";
import { route as searchRoute } from "@faire/web-api--source/routes/www/search";
import { route as suppliersC1Route } from "@faire/web-api--source/routes/www/suppliers/c1SuppliersUrlId";
import { route as suppliersC1BrandValueRoute } from "@faire/web-api--source/routes/www/suppliers/c1SuppliersUrlId/brand-value/brandValue";
import { route as suppliersC2Route } from "@faire/web-api--source/routes/www/suppliers/c1SuppliersUrlId/subcategory/c2SuppliersUrlId";
import { route as suppliersC3Route } from "@faire/web-api--source/routes/www/suppliers/c1SuppliersUrlId/subcategory/c2SuppliersUrlId/c3SuppliersUrlId";

const enum SignUpShownStatus {
  NOT_SHOWN,
  SHOWN_ONCE,
  SHOWN_TWICE,
}

let popSignUpCount: SignUpShownStatus = SignUpShownStatus.NOT_SHOWN;
let ignoreNextScroll: boolean = false;
let hasNavigated: boolean = true;

const autoSignUpOnScroll = () => {
  // we only want to react to the scroll event if the user has first navigated to a different page
  if (
    !ignoreNextScroll &&
    !isSamePathname(
      AppHistory.get().location?.pathname,
      AppHistory.get().previousLocation()?.pathname
    )
  ) {
    autoSignUp();
  }
  ignoreNextScroll = false;
};

export const initializeAutoSignUp = () => {
  if (Storage.session.getItem(HAS_CLOSED_SIGNUP_MODAL)) {
    popSignUpCount = SignUpShownStatus.SHOWN_TWICE;
  } else {
    getWindow()?.addEventListener("scroll", autoSignUpOnScroll);
  }

  AppHistory.get().history.listen((newLocation) => {
    if (
      !isSamePathname(newLocation.pathname, AppHistory.get().location?.pathname)
    ) {
      hasNavigated = true;

      // this is to handle cases where a user has scrolled partway down the page, and then navigates
      //   this causes a scroll event (to top of page) which in turn triggers the autoSignUp
      //   we want to ignore this scroll event
      if (getWindow()?.scrollY !== 0) {
        ignoreNextScroll = true;
      }
    }
  });

  // If we've scrolled before page load (i.e., while browsing SSR content),
  // we want to behave as if debouncedAutoSignUp() was called.
  const lastScrollBeforePageLoad = getWindow()?.mostRecentScrollTime;
  if (lastScrollBeforePageLoad) {
    if (lastScrollBeforePageLoad === getWindow()?.mostRecentScrollTime) {
      delay(0).then(() => {
        autoSignUp();
      });
    }
  }
};

export const autoSignUp = () => {
  if (shouldPopSignUp()) {
    notifySignupSnackbarEvent(SignUpSnackbarEvent.AUTO_SIGNUP_OPENED);

    if (popSignUpCount === SignUpShownStatus.NOT_SHOWN) {
      popSignUpCount = SignUpShownStatus.SHOWN_ONCE;
      hasNavigated = false;
    } else if (popSignUpCount === SignUpShownStatus.SHOWN_ONCE) {
      popSignUpCount = SignUpShownStatus.SHOWN_TWICE;
      hasNavigated = false;
    }

    if (
      getSettingSecondPageAutoModal() &&
      popSignUpCount === SignUpShownStatus.SHOWN_TWICE
    ) {
      getWindowOrThrow().removeEventListener("scroll", autoSignUpOnScroll);
    }

    popSignUp(AppHistory.get().history, SignUpType.AUTO, () => {}, {
      element: SignUpElement.AUTO_POPUP,
      sourceId: "auto_signup",
    });
  }
};

const shouldPopSignUp = () => {
  const matcher = new RouteMatcher(whitelist);
  const discoverMatcher = new RouteMatcher([discoverRoute]);
  const isFaireDirectActiveInLoggedOut = !!getFaireDirectBrandToken();
  const canShowSecondPageModal =
    popSignUpCount !== SignUpShownStatus.SHOWN_TWICE && hasNavigated;
  const hasSignupModalClosed = getSettingSecondPageAutoModal()
    ? !canShowSecondPageModal
    : Storage.session.getItem(HAS_CLOSED_SIGNUP_MODAL);
  const isSimplifiedSemDiscoverV2 =
    isComingFromSEM(AppHistory.get().location) &&
    discoverMatcher.match(AppHistory.get().location?.pathname ?? "") &&
    getSettingSimplifiedSemDiscoverV2();

  // attempting to see modal for the second time
  if (
    popSignUpCount === SignUpShownStatus.SHOWN_ONCE &&
    hasNavigated &&
    matcher.match(AppHistory.get().location?.pathname ?? "")
  ) {
    // if user has already navigated to a different page, we can assign the setting
    assignSettingSecondPageAutoModal();

    // if control group, we don't want to show the modal again
    if (!getSettingSecondPageAutoModal()) {
      getWindowOrThrow().removeEventListener("scroll", autoSignUpOnScroll);
    }
  }

  return (
    getIsProduction() &&
    !isLoggedInRetailerOrBrandPreview() &&
    !isComingFromFacebookOrInstagram() &&
    !isFaireDirectActiveInLoggedOut &&
    !AppModalStore.get().isModalShowing &&
    matcher.match(AppHistory.get().location?.pathname ?? "") &&
    !hasSignupModalClosed &&
    !getIsRobot() &&
    !isSimplifiedSemDiscoverV2
  );
};

const whitelist = [
  homeRoute,
  brandRoute,
  productRoute,
  collectionRoute,
  searchRoute,
  discoverRoute,
  exploreRoute,
  c1Route,
  c1BrandValueRoute,
  c2Route,
  c2BrandValueRoute,
  c3Route,
  c3BrandValueRoute,
  suppliersC3Route,
  suppliersC1Route,
  suppliersC1BrandValueRoute,
  suppliersC2Route,
];

const isSamePathname = (
  p1: string | undefined,
  p2: string | undefined
): boolean => {
  if (p1 === undefined || p2 === undefined) {
    return p1 === p2;
  }
  return (
    decodeURI(removeLocaleFromUrl(p1)) === decodeURI(removeLocaleFromUrl(p2))
  );
};

export const resetAutoSignUpState = () => {
  popSignUpCount = SignUpShownStatus.NOT_SHOWN;
  hasNavigated = true;
  ignoreNextScroll = false;
};
