import { trackClientEvent } from '@faire/web-api--source/trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey =
  'web_retailer_server-nextjs_router_cache_hit-action_unknown';

/**
 * Metric that tracks whether a given navigation from the NextJS Router was possibly cached.  Rudimentary metric that doesn't account for cache eviction etc.

https://www.notion.so/faire/NextJS-Client-Router-Cache-Hit-Tracking-1a62efb5c25a80218cdbf3fb347fdda3?pvs=4
 */
export function trackWebRetailerServerNextjsRouterCacheHit(
  url: string,
  previous_url2: string,
  cache_used3: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: { url, previous_url2, cache_used3 },
  });
}
