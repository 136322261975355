import { ISettingEntry } from "@faire/web-api--source/indigofair/settings/ISettingEntry";

import { IFrontendSetting } from "./IFrontendSetting";
import {
  FrontendSettingName,
  ISettingSchema,
  SettingValue,
} from "./ISettingSchema";
import { setting } from "./setting";

export function experiment<
  T extends SettingValue,
  S extends FrontendSettingName,
  C extends keyof typeof ISettingEntry.SerializeToClient
>(schema: Omit<ISettingSchema<T, S, C>, "type">): IFrontendSetting<T, S, C> {
  return setting({
    type: ISettingEntry.SettingType.EXPERIMENT,
    testable: true,
    ...schema,
  });
}
