"use client";

import { QueryClientProvider as ReactQueryClientProvider } from "@tanstack/react-query";
import React, { useState } from "react";

import { buildQueryClient } from "./reactQueryClient";

export const QueryClientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [queryClient] = useState(() => buildQueryClient());

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
};
