import { ssoCache } from "@faire/retailer-visitor-shared/features/SignUp/SSORequestData";
import { StrictLocalizeFunction } from "@faire/web--source/common/localization/localize";
import { createContextStore } from "@faire/web--source/ui/hooks/ContextStore";
import { ILoginResponse } from "@faire/web-api--source/indigofair/data/ILoginResponse";
import { ISignUpSuggestions } from "@faire/web-api--source/indigofair/data/ISignUpSuggestions";
import { SocialSignOnProvider } from "@faire/web-api--source/indigofair/data/SocialSignOnProvider";

export const BLANK_SIGN_UP_SUGGESTIONS = ISignUpSuggestions.build({
  email_address: "",
  first_name: "",
  last_name: "",
  profile_image_url: "",
  provider: SocialSignOnProvider.SOCIAL_SIGN_ON_PROVIDER_UNKNOWN,
});

type SSOContextStoreType = {
  signUpSuggestions: ISignUpSuggestions;
  isActive: boolean;
  userClickedSSOButton: boolean;
  id_token: string;
  provider: SocialSignOnProvider;
  providers: SocialSignOnProvider[];
  errorMessage: string;
  mfaDescription: string;
  mfaTitle: string;
  mfaOptions: ILoginResponse.IMfaOption[];
  promptSSO: boolean;
  userHasPassword: boolean;
  showConfirmPasswordView: boolean;
  strictLocalize: StrictLocalizeFunction | undefined;
};

const { useStore: useSSOContextStore, Provider: SSOContextStoreProvider } =
  createContextStore<SSOContextStoreType>({
    signUpSuggestions:
      ssoCache.get()?.signUpSuggestions ?? BLANK_SIGN_UP_SUGGESTIONS,
    isActive: ssoCache.get()?.isActive ?? false,
    userClickedSSOButton: false,
    id_token: ssoCache.get()?.id_token ?? "",
    // Provider chosen by the user
    provider:
      (ssoCache.get()?.signUpSuggestions?.provider as
        | SocialSignOnProvider
        | undefined) ?? SocialSignOnProvider.SOCIAL_SIGN_ON_PROVIDER_UNKNOWN,
    // All possible providers
    providers: [],
    errorMessage: "",
    mfaDescription: "",
    mfaTitle: "",
    mfaOptions: [],
    promptSSO: false,
    userHasPassword: false,
    showConfirmPasswordView: false,
    strictLocalize: undefined,
  });

export { useSSOContextStore, SSOContextStoreProvider };
