import { SettingConst } from '@faire/web-api--source/types';

/**
 * Controls whether or not we use server components to render the logged-in brand page
 */
export const NEXTJS_RSC_LIBP_MIGRATION_241121 =
  'NEXTJS_RSC_LIBP_MIGRATION_241121' as SettingConst<
    'NEXTJS_RSC_LIBP_MIGRATION_241121',
    boolean,
    'RETAILER' | 'RETAILER_HEAD_TAGS' | 'VISITOR_WEB' | 'VISITOR_WEB_HEAD_TAGS'
  >;
