import React, { Suspense, useEffect, useState } from "react";

import { LazyAppDownloadNudge } from "@faire/retailer-visitor-shared/components/AppDownloadNudge/LazyAppDownloadNudge";
import { useCanShowAppDownloadNudge } from "@faire/retailer-visitor-shared/components/AppDownloadNudge/useCanShowAppDownloadNudge";

const DELAY_MS = 4000;

export const AppDownloadNudgeWrapper: React.FC = () => {
  const canShowNudge = useCanShowAppDownloadNudge();
  const [isDelayingRender, setIsDelayingRender] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelayingRender(false);
    }, DELAY_MS);

    return () => clearTimeout(timer);
  }, []);

  if (!canShowNudge || isDelayingRender) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyAppDownloadNudge />
    </Suspense>
  );
};
