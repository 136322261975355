import { getSocialProviderText } from "@faire/retailer-visitor-shared/lib/ssoUtils";
import {
  DEPRECATED_backwardsCompatibleValues,
  StrictLocalizeFunction,
} from "@faire/web--source/common/localization/localize";
import { SocialSignOnProvider } from "@faire/web-api--source/indigofair/data/SocialSignOnProvider";

interface SSOError {
  provider: SocialSignOnProvider;
  code: string;
  details?: string;
}

export const extractMessage = (
  { provider, code, details }: SSOError,
  strictLocalize: StrictLocalizeFunction
): string => {
  const providerName = getSocialProviderText(provider);
  switch (code) {
    case "idpiframe_initialization_failed":
      return strictLocalize({
        defaultMessage:
          "Please make sure cookies are enabled in your browser window and refresh page.",
        description: {
          text: "Error message informing the user that Sign-In with Google/Apple/Facebook isn't working",
        },
      });
    case "popup_closed_by_user":
      return providerName
        ? strictLocalize(
            {
              defaultMessage:
                "{providerName} authorization failed, please try again.",
              description: {
                text: "Error message informing the user that Sign-In with Google/Apple/Facebook isn't working",
              },
            },
            DEPRECATED_backwardsCompatibleValues({ providerName })
          )
        : strictLocalize({
            defaultMessage: "Authorization failed, please try again.",
            description: {
              text: "Error message informing the user that Sign-In with Google/Apple/Facebook isn't working",
            },
          });
    case "access_denied":
      return strictLocalize({
        defaultMessage:
          "Access to name and email address is required, please try again.",
        description: {
          text: "Error message informing the user that Sign-In with Google/Apple/Facebook isn't working",
        },
      });
    case "immediate_failed":
      return defaultSSOErrorMessage(strictLocalize);
    default:
      return details ?? defaultSSOErrorMessage(strictLocalize);
  }
};

export const defaultSSOErrorMessage = (
  strictLocalize: StrictLocalizeFunction
) =>
  strictLocalize({
    defaultMessage: "An error occurred, please try again.",
    description: {
      text: "Error message informing the user that Sign-In with Google/Apple/Facebook isn't working",
    },
  });
