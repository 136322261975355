"use client";

import { useMarketStore } from "@faire/retailer-visitor-shared/stores/domain/Events/FaireMarkets/MarketStore";
import { useSSOErrorStore } from "@faire/retailer-visitor-shared/stores/domain/SSO/useSSOErrorStore";
import { UserStore } from "@faire/retailer-visitor-shared/stores/domain/UserStore";
import { MessengerFactory } from "@faire/retailer-visitor-shared/stores/MessengerFactory";
import { useStrictLocalization } from "@faire/web--source/common/localization";
import { isWindowUndefined } from "@faire/web--source/common/server-side-rendering/isWindowUndefined";
import { MessengerStore } from "@faire/web--source/messenger/store/MessengerStore";
import { useRef } from "react";

/**
 * Runs once Providers have been initialized.
 */
export const useInitializeLocalization = () => {
  const { setStrictLocalize } = useSSOErrorStore();
  const { strictLocalize } = useStrictLocalization();
  const { setStrictLocalize: setStrictLocalizeMarketStore } = useMarketStore([
    "setStrictLocalize",
  ]);
  const didSet = useRef(false);

  // NOTE(Blair): We want this to only run once,
  // but we also want it to run in SSR (hence no useEffect)
  if (!didSet.current) {
    setStrictLocalizeMarketStore(strictLocalize);
    setStrictLocalize(strictLocalize);
    const user = UserStore.get().user;
    const messengerStore = new MessengerStore(user, strictLocalize);
    // Only init (start connections and fetches) on the client
    if (user && !isWindowUndefined()) {
      messengerStore.init(user, undefined);
    }
    MessengerFactory.get().messengerStore = messengerStore;
    didSet.current = true;
  }
};
