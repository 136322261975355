export namespace IRequestPaginationData {
  /**
   * Return an instance of IRequestPaginationData with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IRequestPaginationData>
  ): IRequestPaginationData =>
    ({
      ...(partial ?? {}),
    }) as IRequestPaginationData;
}

export interface IRequestPaginationData {
  page_number: number | undefined;
  page_size: number | undefined;
}
