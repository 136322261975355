import { LocationForOrigin } from "@faire/retailer-visitor-shared/lib/navigation-origin/LocationForOrigin";
import { parseQueryParams } from "@faire/web--source/common/query-params/parseQueryParams";

/**
 * SEM stands for Search Engine Marketing
 * @returns true if the utm_medium is cpc
 */
export const isComingFromSEM = (location: LocationForOrigin): boolean => {
  const utmMedium = parseQueryParams(location?.search ?? "").get("utm_medium");
  return utmMedium === "cpc";
};

export const isSemTraffic = (searchParams: Record<string, string>): boolean => {
  return searchParams.utm_medium === "cpc";
};
