import { experiment } from "@faire/web--source/common/settings/declarations/experiment";

/**
 * XP to test the impact of the Cheq AI tag
 */
export const FE_CHEQ_AI_IMPACT_TEST_250326 = experiment({
  name: "FE_CHEQ_AI_IMPACT_TEST_250326",
  identifier: "SessionToken",
  client: [
    "RETAILER",
    "RETAILER_HEAD_TAGS",
    "VISITOR_WEB_HEAD_TAGS",
    "VISITOR_WEB",
  ],
  notifyAfter: "2025-05-31",
  defaultValue: false,
  productArea: "INFRA",
});
