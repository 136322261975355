"use client";

import { LazyDevTools } from "@faire/retailer-visitor-shared/components/DevTools/index";
import { isE2E } from "@faire/retailer-visitor-shared/lib/isE2E";
import { getIsProduction } from "@faire/retailer-visitor-shared/serialized-data/getEnvName";
import { useIsSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import React, { Suspense } from "react";

export const DevTools = React.memo(() => {
  const isSSR = useIsSSR();
  if (getIsProduction() || isE2E() || isSSR) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyDevTools />
    </Suspense>
  );
});
