"use client";
import { create } from "zustand";

interface Store {
  isTransitioning: boolean;
  setIsTransitioning: (isTransitioning: boolean) => void;
}

export const useRouterTransitioning = create<Store>((set) => ({
  isTransitioning: false,
  setIsTransitioning: (isTransitioning: boolean) => {
    set({ isTransitioning });
  },
}));
