"use client";

import { getReleaseSha } from "@faire/retailer-visitor-shared/serialized-data/getReleaseSha";
import { useSettingEnableReactScanMonitoring } from "@faire/retailer-visitor-shared/settings/useSettingEnableReactScanMonitoring";
import { NoSSR } from "@faire/web--source/common/NoSSR";
import React, { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

type Props = {
  apiKey: string | undefined;
};

export const ReactScanMonitoring: React.FC<Props> = ({ apiKey }) => {
  const enableReactScanMonitoring = useSettingEnableReactScanMonitoring();

  if (!enableReactScanMonitoring) {
    return null;
  }

  return (
    <NoSSR>
      <ErrorBoundary fallback={null}>
        {apiKey ? (
          <Suspense fallback={null}>
            <LazyReactScanMonitoring
              apiKey={apiKey}
              commit={getReleaseSha()}
              branch="_"
            />
          </Suspense>
        ) : null}
      </ErrorBoundary>
    </NoSSR>
  );
};

const LazyReactScanMonitoring = lazy(() =>
  import("react-scan/monitoring/next").then((m) => ({
    default: m.Monitoring,
  }))
);
