import { SocialSignOnProvider } from '@faire/web-api--source/indigofair/data/SocialSignOnProvider';

export namespace ISignUpSuggestions {
  /**
   * Return an instance of ISignUpSuggestions with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<ISignUpSuggestions>
  ): ISignUpSuggestions =>
    ({
      ...(partial ?? {}),
    }) as ISignUpSuggestions;
}

export interface ISignUpSuggestions {
  email_address: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  profile_image_url: string | undefined;
  provider: keyof typeof SocialSignOnProvider | undefined;
}
