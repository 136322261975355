import { request } from '@faire/web-api--source';
import { IShowNuxAppDownloadNudgeResponse } from '@faire/web-api--source/indigofair/data/IShowNuxAppDownloadNudgeResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/retailer/show-nux-app-download-nudge`;
};

export const build = (
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/retailer/show-nux-app-download-nudge';

/**
 * Product Area: RETAILER_MOBILE
 */
function execute(): Promise<IShowNuxAppDownloadNudgeResponse>;
function execute(
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IShowNuxAppDownloadNudgeResponse>>;
function execute(
  options?: EndpointOptions
): Promise<IShowNuxAppDownloadNudgeResponse>;
function execute(
  options?: EndpointOptions
): Promise<
  | IShowNuxAppDownloadNudgeResponse
  | SuccessResponse<IShowNuxAppDownloadNudgeResponse>
> {
  const { route, ...requestOptions } = build(options);
  return request<IShowNuxAppDownloadNudgeResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/retailer/show-nux-app-download-nudge/get';
export default execute;
